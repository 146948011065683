#bundleContainer .product-item-title, #set-container .product-item-title {
  display: -webkit-box;
  height: 3.5rem;
  line-height: 1.7rem;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden; }

#bundleContainer .product-item-amount, #set-container .product-item-amount {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: #575757 2px solid;
  font-size: 12px;
  color: #575757;
  line-height: 35px;
  text-align: center;
  background: rgba(255, 255, 255, 0.75);
  top: calc(50% - 20px);
  left: calc(50% - 20px); }

#bundleContainer .bundleItemContainer, #set-container .bundleItemContainer {
  min-height: 20rem; }
  #bundleContainer .bundleItemContainer .productData:last-of-type .ra-icon.plus, #set-container .bundleItemContainer .productData:last-of-type .ra-icon.plus {
    display: none; }
  #bundleContainer .bundleItemContainer .bundle-quantity, #set-container .bundleItemContainer .bundle-quantity {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: #575757 2px solid;
    font-size: 12px;
    color: #575757;
    line-height: 35px;
    text-align: center;
    background: rgba(255, 255, 255, 0.75);
    top: calc(50% - 20px);
    left: calc(50% - 20px); }

#bundleContainer .iconContainer, #set-container .iconContainer {
  text-align: center;
  margin: auto; }
  #bundleContainer .iconContainer i, #set-container .iconContainer i {
    font-size: 3rem; }
    @media (max-width: 576px) {
      #bundleContainer .iconContainer i, #set-container .iconContainer i {
        font-size: 2rem; } }

#bundleContainer .inputContainer input[type=checkbox]:not(:checked) + div.bundle-amount-group-outer, #set-container .inputContainer input[type=checkbox]:not(:checked) + div.bundle-amount-group-outer {
  display: none; }

#bundleContainer .inputContainer input[type=checkbox]:not(:checked) + input + label, #set-container .inputContainer input[type=checkbox]:not(:checked) + input + label {
  color: #e3e3e3;
  font-weight: normal !important; }

#bundleContainer .inputContainer input[type=checkbox]:not(:checked) + div + label, #set-container .inputContainer input[type=checkbox]:not(:checked) + div + label {
  color: #e3e3e3;
  font-weight: normal !important; }

#bundleContainer .bundleCheckout, #bundleContainer .set-checkout-col, #set-container .bundleCheckout, #set-container .set-checkout-col {
  padding: 1rem;
  background-color: #f6f6f6; }
  #bundleContainer .bundleCheckout b, #bundleContainer .set-checkout-col b, #set-container .bundleCheckout b, #set-container .set-checkout-col b {
    font-size: 1.6rem; }
  #bundleContainer .bundleCheckout .price, #bundleContainer .set-checkout-col .price, #set-container .bundleCheckout .price, #set-container .set-checkout-col .price {
    margin-top: 1.5rem;
    font-size: 2.4rem;
    font-weight: bold;
    color: #94c947; }
  #bundleContainer .bundleCheckout .vat-box, #bundleContainer .set-checkout-col .vat-box, #set-container .bundleCheckout .vat-box, #set-container .set-checkout-col .vat-box {
    margin-top: 0.5rem;
    font-style: italic; }
  #bundleContainer .bundleCheckout #bundleToBasket-bundle, #bundleContainer .bundleCheckout #bundleToBasket-set, #bundleContainer .bundleCheckout #set-to-basket, #bundleContainer .set-checkout-col #bundleToBasket-bundle, #bundleContainer .set-checkout-col #bundleToBasket-set, #bundleContainer .set-checkout-col #set-to-basket, #set-container .bundleCheckout #bundleToBasket-bundle, #set-container .bundleCheckout #bundleToBasket-set, #set-container .bundleCheckout #set-to-basket, #set-container .set-checkout-col #bundleToBasket-bundle, #set-container .set-checkout-col #bundleToBasket-set, #set-container .set-checkout-col #set-to-basket {
    font-weight: bold;
    width: 100%;
    height: 5.5rem;
    font-size: 1.6rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden; }
    #bundleContainer .bundleCheckout #bundleToBasket-bundle:disabled, #bundleContainer .bundleCheckout #bundleToBasket-set:disabled, #bundleContainer .bundleCheckout #set-to-basket:disabled, #bundleContainer .set-checkout-col #bundleToBasket-bundle:disabled, #bundleContainer .set-checkout-col #bundleToBasket-set:disabled, #bundleContainer .set-checkout-col #set-to-basket:disabled, #set-container .bundleCheckout #bundleToBasket-bundle:disabled, #set-container .bundleCheckout #bundleToBasket-set:disabled, #set-container .bundleCheckout #set-to-basket:disabled, #set-container .set-checkout-col #bundleToBasket-bundle:disabled, #set-container .set-checkout-col #bundleToBasket-set:disabled, #set-container .set-checkout-col #set-to-basket:disabled {
      cursor: not-allowed; }
    #bundleContainer .bundleCheckout #bundleToBasket-bundle::before, #bundleContainer .bundleCheckout #bundleToBasket-set::before, #bundleContainer .bundleCheckout #set-to-basket::before, #bundleContainer .set-checkout-col #bundleToBasket-bundle::before, #bundleContainer .set-checkout-col #bundleToBasket-set::before, #bundleContainer .set-checkout-col #set-to-basket::before, #set-container .bundleCheckout #bundleToBasket-bundle::before, #set-container .bundleCheckout #bundleToBasket-set::before, #set-container .bundleCheckout #set-to-basket::before, #set-container .set-checkout-col #bundleToBasket-bundle::before, #set-container .set-checkout-col #bundleToBasket-set::before, #set-container .set-checkout-col #set-to-basket::before {
      content: "";
      position: absolute;
      background-color: #b4d97e;
      width: 10rem;
      height: 200%;
      top: -80%;
      left: -20%;
      transform: rotate(25deg) translate(-100%, 0);
      z-index: 2;
      transition: .35s; }
    #bundleContainer .bundleCheckout #bundleToBasket-bundle::after, #bundleContainer .bundleCheckout #bundleToBasket-set::after, #bundleContainer .bundleCheckout #set-to-basket::after, #bundleContainer .set-checkout-col #bundleToBasket-bundle::after, #bundleContainer .set-checkout-col #bundleToBasket-set::after, #bundleContainer .set-checkout-col #set-to-basket::after, #set-container .bundleCheckout #bundleToBasket-bundle::after, #set-container .bundleCheckout #bundleToBasket-set::after, #set-container .bundleCheckout #set-to-basket::after, #set-container .set-checkout-col #bundleToBasket-bundle::after, #set-container .set-checkout-col #bundleToBasket-set::after, #set-container .set-checkout-col #set-to-basket::after {
      content: "";
      position: absolute;
      background-color: #abd56e;
      width: 10rem;
      height: 200%;
      top: -80%;
      left: -20%;
      transform: rotate(25deg) translate(-100%, 0);
      z-index: 1;
      transition: .5s; }
    #bundleContainer .bundleCheckout #bundleToBasket-bundle .ra-button-inner, #bundleContainer .bundleCheckout #bundleToBasket-set .ra-button-inner, #bundleContainer .bundleCheckout #set-to-basket .ra-button-inner, #bundleContainer .set-checkout-col #bundleToBasket-bundle .ra-button-inner, #bundleContainer .set-checkout-col #bundleToBasket-set .ra-button-inner, #bundleContainer .set-checkout-col #set-to-basket .ra-button-inner, #set-container .bundleCheckout #bundleToBasket-bundle .ra-button-inner, #set-container .bundleCheckout #bundleToBasket-set .ra-button-inner, #set-container .bundleCheckout #set-to-basket .ra-button-inner, #set-container .set-checkout-col #bundleToBasket-bundle .ra-button-inner, #set-container .set-checkout-col #bundleToBasket-set .ra-button-inner, #set-container .set-checkout-col #set-to-basket .ra-button-inner {
      position: absolute;
      top: 25%;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      opacity: 1;
      transition: opacity .175s; }
      #bundleContainer .bundleCheckout #bundleToBasket-bundle .ra-button-inner.hide, #bundleContainer .bundleCheckout #bundleToBasket-set .ra-button-inner.hide, #bundleContainer .bundleCheckout #set-to-basket .ra-button-inner.hide, #bundleContainer .set-checkout-col #bundleToBasket-bundle .ra-button-inner.hide, #bundleContainer .set-checkout-col #bundleToBasket-set .ra-button-inner.hide, #bundleContainer .set-checkout-col #set-to-basket .ra-button-inner.hide, #set-container .bundleCheckout #bundleToBasket-bundle .ra-button-inner.hide, #set-container .bundleCheckout #bundleToBasket-set .ra-button-inner.hide, #set-container .bundleCheckout #set-to-basket .ra-button-inner.hide, #set-container .set-checkout-col #bundleToBasket-bundle .ra-button-inner.hide, #set-container .set-checkout-col #bundleToBasket-set .ra-button-inner.hide, #set-container .set-checkout-col #set-to-basket .ra-button-inner.hide {
        opacity: 0; }
    #bundleContainer .bundleCheckout #bundleToBasket-bundle.confirm-add-basket::before, #bundleContainer .bundleCheckout #bundleToBasket-set.confirm-add-basket::before, #bundleContainer .bundleCheckout #set-to-basket.confirm-add-basket::before, #bundleContainer .set-checkout-col #bundleToBasket-bundle.confirm-add-basket::before, #bundleContainer .set-checkout-col #bundleToBasket-set.confirm-add-basket::before, #bundleContainer .set-checkout-col #set-to-basket.confirm-add-basket::before, #set-container .bundleCheckout #bundleToBasket-bundle.confirm-add-basket::before, #set-container .bundleCheckout #bundleToBasket-set.confirm-add-basket::before, #set-container .bundleCheckout #set-to-basket.confirm-add-basket::before, #set-container .set-checkout-col #bundleToBasket-bundle.confirm-add-basket::before, #set-container .set-checkout-col #bundleToBasket-set.confirm-add-basket::before, #set-container .set-checkout-col #set-to-basket.confirm-add-basket::before {
      width: 150%;
      top: -200%;
      height: 500%;
      transform: rotate(25deg) translate(0, 0);
      transition: .75s;
      background-color: #41ad49; }
    #bundleContainer .bundleCheckout #bundleToBasket-bundle:hover:not(:disabled)::before, #bundleContainer .bundleCheckout #bundleToBasket-set:hover:not(:disabled)::before, #bundleContainer .bundleCheckout #set-to-basket:hover:not(:disabled)::before, #bundleContainer .set-checkout-col #bundleToBasket-bundle:hover:not(:disabled)::before, #bundleContainer .set-checkout-col #bundleToBasket-set:hover:not(:disabled)::before, #bundleContainer .set-checkout-col #set-to-basket:hover:not(:disabled)::before, #set-container .bundleCheckout #bundleToBasket-bundle:hover:not(:disabled)::before, #set-container .bundleCheckout #bundleToBasket-set:hover:not(:disabled)::before, #set-container .bundleCheckout #set-to-basket:hover:not(:disabled)::before, #set-container .set-checkout-col #bundleToBasket-bundle:hover:not(:disabled)::before, #set-container .set-checkout-col #bundleToBasket-set:hover:not(:disabled)::before, #set-container .set-checkout-col #set-to-basket:hover:not(:disabled)::before {
      transform: rotate(25deg) translate(0, 0); }
    #bundleContainer .bundleCheckout #bundleToBasket-bundle:hover:not(:disabled)::after, #bundleContainer .bundleCheckout #bundleToBasket-set:hover:not(:disabled)::after, #bundleContainer .bundleCheckout #set-to-basket:hover:not(:disabled)::after, #bundleContainer .set-checkout-col #bundleToBasket-bundle:hover:not(:disabled)::after, #bundleContainer .set-checkout-col #bundleToBasket-set:hover:not(:disabled)::after, #bundleContainer .set-checkout-col #set-to-basket:hover:not(:disabled)::after, #set-container .bundleCheckout #bundleToBasket-bundle:hover:not(:disabled)::after, #set-container .bundleCheckout #bundleToBasket-set:hover:not(:disabled)::after, #set-container .bundleCheckout #set-to-basket:hover:not(:disabled)::after, #set-container .set-checkout-col #bundleToBasket-bundle:hover:not(:disabled)::after, #set-container .set-checkout-col #bundleToBasket-set:hover:not(:disabled)::after, #set-container .set-checkout-col #set-to-basket:hover:not(:disabled)::after {
      transform: rotate(25deg) translate(30%, 0); }
    #bundleContainer .bundleCheckout #bundleToBasket-bundle:disabled, #bundleContainer .bundleCheckout #bundleToBasket-set:disabled, #bundleContainer .bundleCheckout #set-to-basket:disabled, #bundleContainer .set-checkout-col #bundleToBasket-bundle:disabled, #bundleContainer .set-checkout-col #bundleToBasket-set:disabled, #bundleContainer .set-checkout-col #set-to-basket:disabled, #set-container .bundleCheckout #bundleToBasket-bundle:disabled, #set-container .bundleCheckout #bundleToBasket-set:disabled, #set-container .bundleCheckout #set-to-basket:disabled, #set-container .set-checkout-col #bundleToBasket-bundle:disabled, #set-container .set-checkout-col #bundleToBasket-set:disabled, #set-container .set-checkout-col #set-to-basket:disabled {
      pointer-events: none;
      opacity: 1; }
    #bundleContainer .bundleCheckout #bundleToBasket-bundle .ra-icon, #bundleContainer .bundleCheckout #bundleToBasket-set .ra-icon, #bundleContainer .bundleCheckout #set-to-basket .ra-icon, #bundleContainer .set-checkout-col #bundleToBasket-bundle .ra-icon, #bundleContainer .set-checkout-col #bundleToBasket-set .ra-icon, #bundleContainer .set-checkout-col #set-to-basket .ra-icon, #set-container .bundleCheckout #bundleToBasket-bundle .ra-icon, #set-container .bundleCheckout #bundleToBasket-set .ra-icon, #set-container .bundleCheckout #set-to-basket .ra-icon, #set-container .set-checkout-col #bundleToBasket-bundle .ra-icon, #set-container .set-checkout-col #bundleToBasket-set .ra-icon, #set-container .set-checkout-col #set-to-basket .ra-icon {
      font-size: 2.9rem !important;
      vertical-align: text-top !important;
      margin-top: -.7rem; }
  #bundleContainer .bundleCheckout .set-info-headline, #bundleContainer .set-checkout-col .set-info-headline, #set-container .bundleCheckout .set-info-headline, #set-container .set-checkout-col .set-info-headline {
    margin: 2rem 0 0 0;
    font-weight: 700; }
  #bundleContainer .bundleCheckout .set-saved-percent, #bundleContainer .set-checkout-col .set-saved-percent, #set-container .bundleCheckout .set-saved-percent, #set-container .set-checkout-col .set-saved-percent {
    margin: 2rem 0 0 0;
    color: #dc371c;
    font-weight: 700; }
  #bundleContainer .bundleCheckout .bundle-sub, #bundleContainer .set-checkout-col .bundle-sub, #set-container .bundleCheckout .bundle-sub, #set-container .set-checkout-col .bundle-sub {
    margin-top: 0.5rem;
    font-weight: bold; }
    #bundleContainer .bundleCheckout .bundle-sub .unit-string, #bundleContainer .set-checkout-col .bundle-sub .unit-string, #set-container .bundleCheckout .bundle-sub .unit-string, #set-container .set-checkout-col .bundle-sub .unit-string {
      font-weight: normal; }

#accessoriesContainer, .accessoriesContainer, #replacementContainer,
.replacementContainer, #alternativeContainer, .alternativeContainer,
#accessorieforContainer, .accessorieforContainer, .insetContainer, #insetContainer {
  overflow: hidden; }
  #accessoriesContainer div.productData, .accessoriesContainer div.productData, #replacementContainer div.productData,
  .replacementContainer div.productData, #alternativeContainer div.productData, .alternativeContainer div.productData,
  #accessorieforContainer div.productData, .accessorieforContainer div.productData, .insetContainer div.productData, #insetContainer div.productData {
    overflow: hidden; }
    #accessoriesContainer div.productData p, .accessoriesContainer div.productData p, #replacementContainer div.productData p,
    .replacementContainer div.productData p, #alternativeContainer div.productData p, .alternativeContainer div.productData p,
    #accessorieforContainer div.productData p, .accessorieforContainer div.productData p, .insetContainer div.productData p, #insetContainer div.productData p {
      text-align: unset !important; }

.tab-inner .middle-text {
  margin-top: 1rem;
  background-color: #e3e3e3;
  position: relative;
  display: block;
  cursor: pointer;
  color: #000000;
  opacity: .6;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 1rem 0;
  height: 4rem; }

.tab-inner.margin-bottom-5 {
  margin-bottom: 5rem; }

.crosssellingContainer {
  margin-top: 4rem; }
  .crosssellingContainer #basketCrosssellingCarousel .carousel-inner .row, .crosssellingContainer #basketCrossselingCarouselMobile .carousel-inner .row {
    padding: 0 3rem; }
  .crosssellingContainer #basketCrosssellingCarousel .minibasketControl, .crosssellingContainer #basketCrossselingCarouselMobile .minibasketControl {
    width: 3rem; }

.bundle-items {
  list-style: none;
  padding: 0 !important;
  margin-top: 1rem; }

@media (max-width: 768px) {
  .bundle-item {
    margin-top: .5rem;
    border-bottom: solid 1px #e3e3e3; }
    .bundle-item:last-child {
      border-bottom: 0; } }

@media (min-width: 769px) {
  .bundle-item > div {
    line-height: 3.5rem;
    max-height: 4rem; } }

.bundle-item .bundleList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: .5rem;
  margin-top: .5rem; }
  @media (min-width: 769px) {
    .bundle-item .bundleList {
      align-items: center; } }

.bundle-item label {
  display: inline; }

.bundle-item .bundleCheckbox {
  width: 25px; }

@media (max-width: 768px) {
  .bundle-item .bundlePrice {
    flex-basis: 50%;
    float: right;
    margin-bottom: .5rem;
    margin-left: .5rem; } }

.bundle-item .bundle-amount-group-outer {
  margin-right: .5rem; }

@media (max-width: 768px) {
  .bundle-item .bundle-amount-group {
    min-width: 7rem; } }

.bundle-item .bundle-amount-group input.bundleAmount {
  padding: 0;
  max-width: 4rem;
  height: 3rem;
  text-align: center; }
  .bundle-item .bundle-amount-group input.bundleAmount:focus {
    border-left: none;
    border-right: none;
    box-shadow: none; }
  @media (max-width: 576px) {
    .bundle-item .bundle-amount-group input.bundleAmount {
      max-width: 7rem; } }

.bundle-item .bundle-amount-group .btn-left {
  background-color: #FFFFFF;
  border: 1px solid #aeaeae;
  border-right: none;
  box-shadow: none;
  height: 3rem; }

.bundle-item .bundle-amount-group .btn-right {
  background-color: #FFFFFF;
  border: 1px solid #aeaeae;
  border-left: none;
  box-shadow: none;
  height: 3rem; }

#accessoriesContainer .productBox, #accessorieforContainer .productBox, #replacementContainer .productBox, #alsoboughtContainer .productBox, #lastseenContainer .productBox, #insetContainer .productBox, #replacementforContainer .productBox, #articleseriesContainer .productBox {
  border: 1px solid #e3e3e3;
  border-top: none;
  border-right: none;
  padding: 0; }
  #accessoriesContainer .productBox:nth-child(4n), #accessorieforContainer .productBox:nth-child(4n), #replacementContainer .productBox:nth-child(4n), #alsoboughtContainer .productBox:nth-child(4n), #lastseenContainer .productBox:nth-child(4n), #insetContainer .productBox:nth-child(4n), #replacementforContainer .productBox:nth-child(4n), #articleseriesContainer .productBox:nth-child(4n) {
    border-right: 1px solid #e3e3e3; }
  #accessoriesContainer .productBox:last-of-type, #accessorieforContainer .productBox:last-of-type, #replacementContainer .productBox:last-of-type, #alsoboughtContainer .productBox:last-of-type, #lastseenContainer .productBox:last-of-type, #insetContainer .productBox:last-of-type, #replacementforContainer .productBox:last-of-type, #articleseriesContainer .productBox:last-of-type {
    border-right: 1px solid #e3e3e3; }
  #accessoriesContainer .productBox:nth-of-type(-n+4), #accessorieforContainer .productBox:nth-of-type(-n+4), #replacementContainer .productBox:nth-of-type(-n+4), #alsoboughtContainer .productBox:nth-of-type(-n+4), #lastseenContainer .productBox:nth-of-type(-n+4), #insetContainer .productBox:nth-of-type(-n+4), #replacementforContainer .productBox:nth-of-type(-n+4), #articleseriesContainer .productBox:nth-of-type(-n+4) {
    border-top: 1px solid #e3e3e3; }

.article-series-desc {
  margin-bottom: 2rem; }

#details_container.margin-header {
  margin-top: 2rem; }

#details_container .linkToNoticeList {
  display: none; }
  #details_container .linkToNoticeList.on-list {
    color: #94c947; }

@media (max-width: 767.98px) {
  #details_container .details-infos {
    display: flex;
    flex-direction: column;
    padding: 0 1rem; }
    #details_container .details-infos .star-ratings {
      order: 1;
      height: auto !important; }
      #details_container .details-infos .star-ratings .stars {
        float: right; }
    #details_container .details-infos .ra-details-title, #details_container .details-infos .ra-details-oxvarselect-tooltip-trigger, #details_container .details-infos .spacer, #details_container .details-infos .details-paragraph, #details_container .details-infos .mobile-ribbons {
      order: 2; }
    #details_container .details-infos .sticky {
      position: relative !important;
      top: 0;
      order: 0;
      margin: 0 -1rem 2rem -1rem; }
  #details_container .details-sidebar {
    background-color: transparent !important; }
  #details_container .ra-details-keywords, #details_container .tabs-outer {
    padding: 0 1rem; }
  #details_container .mobile-ribbons {
    justify-content: flex-start;
    justify-items: flex-start;
    width: 100%; }
    #details_container .mobile-ribbons .ribbon.special {
      height: 3rem;
      line-height: 3rem; }
    #details_container .mobile-ribbons .attribute-ribbons {
      top: 0; }
      #details_container .mobile-ribbons .attribute-ribbons .attribute-ribbon {
        width: 3rem !important;
        height: 3rem !important; }
        #details_container .mobile-ribbons .attribute-ribbons .attribute-ribbon .ra-icon {
          line-height: 3rem !important;
          font-size: 2rem !important; }
      #details_container .mobile-ribbons .attribute-ribbons .custom-svg {
        height: 3rem !important; }
        #details_container .mobile-ribbons .attribute-ribbons .custom-svg svg {
          height: 2rem !important; }
  #details_container .summary-anchor {
    padding: 0 1rem; }
  #details_container .linkToNoticeList {
    position: absolute;
    right: 0;
    top: 2rem;
    z-index: 980;
    width: 3rem;
    height: 3rem;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    line-height: 3rem;
    font-size: 2.5rem;
    display: block; }
  #details_container .share-icon-box.mobile {
    position: absolute;
    right: 0;
    top: 6rem;
    z-index: 980; }
    #details_container .share-icon-box.mobile .share-icon-box-inner {
      width: 3rem;
      height: 3rem;
      background-color: rgba(255, 255, 255, 0.8);
      text-align: center;
      line-height: 3rem;
      font-size: 2.5rem; }
  #details_container .details-contact {
    padding-top: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: -1rem;
    margin-right: -1rem; }
  #details_container .ra-details-keywords {
    padding: 1rem; } }

#details_container h1.details-header {
  margin-top: 0;
  line-height: 3rem;
  padding-right: 2rem; }

#details_container h2.details-header {
  margin-bottom: 1rem;
  line-height: 1.5rem; }

#details_container div.spacer {
  height: 2.5rem; }

#details_container p.details-paragraph, #details_container span.details-paragraph {
  display: block;
  margin-right: 1rem; }

#details_container .information-rule {
  font-weight: 400; }

#details_container .variants-table tr td {
  vertical-align: middle; }

#details_container .variants-table .ribbons {
  height: 2.5rem;
  padding: 0;
  margin-bottom: -1.5rem;
  margin-right: -1.5rem; }
  #details_container .variants-table .ribbons .ribbon {
    display: inline-flex;
    flex-direction: row;
    position: relative;
    height: 2.5rem;
    top: -1.7rem;
    left: -1.7rem;
    float: left;
    margin-right: .3rem; }

#details_container .variants-table button {
  border: 0 !important;
  background: none !important;
  min-height: auto;
  height: auto;
  line-height: 0;
  padding: 0; }
  #details_container .variants-table button .ra-icon {
    font-size: 2.5rem;
    vertical-align: text-top;
    color: #94c947; }
    #details_container .variants-table button .ra-icon:hover {
      color: #575757; }

#details_container .variants-table .ra-icon.info {
  color: #575757; }

#details_container .variants-table .flag-icon {
  font-size: 1.5rem;
  margin-top: -.3rem; }

#details_container .variants-table .ribbon-triangle {
  position: absolute;
  margin-top: .8rem;
  margin-left: -1.7rem;
  width: 0;
  height: 0;
  border-top: 0.5rem solid #575757;
  border-left: 1rem solid transparent; }

#details_container .details-sidebar {
  margin-top: 2rem;
  padding: 1rem 0;
  background-color: #f6f6f6;
  color: #575757;
  position: relative; }
  @media (max-width: 767.98px) {
    #details_container .details-sidebar {
      margin-top: 0; } }
  #details_container .details-sidebar .ra-sidebar-top-share-and-noticelist {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex; }
    #details_container .details-sidebar .ra-sidebar-top-share-and-noticelist #ra-details-share-btn {
      display: inline-flex;
      cursor: pointer; }
    #details_container .details-sidebar .ra-sidebar-top-share-and-noticelist .ra-icon {
      font-size: 3rem; }
    #details_container .details-sidebar .ra-sidebar-top-share-and-noticelist .linkToNoticeList {
      display: inline-flex; }
      #details_container .details-sidebar .ra-sidebar-top-share-and-noticelist .linkToNoticeList .ra-icon {
        font-size: 3rem; }
      #details_container .details-sidebar .ra-sidebar-top-share-and-noticelist .linkToNoticeList span {
        display: none !important; }
    @media (max-width: 767.98px) {
      #details_container .details-sidebar .ra-sidebar-top-share-and-noticelist {
        display: none; } }
  #details_container .details-sidebar label {
    font-weight: bold;
    margin-bottom: .4rem; }
    #details_container .details-sidebar label small {
      margin-left: .5rem;
      font-weight: normal; }
  #details_container .details-sidebar .ribbons {
    height: 2.5rem;
    padding: 0; }
    #details_container .details-sidebar .ribbons .ribbon {
      display: inline-flex;
      flex-direction: row;
      position: relative;
      height: 2.5rem;
      top: -2rem;
      left: -1rem;
      float: left;
      margin-right: .3rem; }
  #details_container .details-sidebar .ra-icon {
    font-size: 2rem;
    vertical-align: text-top; }
  #details_container .details-sidebar .flag-icon {
    font-size: 1.5rem;
    margin-top: -.3rem; }
  #details_container .details-sidebar .ribbon-triangle {
    position: absolute;
    margin-top: .5rem;
    margin-left: -1rem;
    width: 0;
    height: 0;
    border-top: 0.5rem solid #575757;
    border-left: 1rem solid transparent; }
  #details_container .details-sidebar .price {
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: bold; }
    #details_container .details-sidebar .price.ra-details-price span {
      font-size: 1.4rem;
      color: #575757; }
    #details_container .details-sidebar .price.ra-details-netto-price {
      font-size: 1.6rem;
      line-height: 1.6rem;
      font-weight: bold;
      color: #575757; }
      #details_container .details-sidebar .price.ra-details-netto-price span {
        font-size: 1.4rem;
        color: #575757; }
    #details_container .details-sidebar .price.price-big {
      font-size: 3.4rem;
      line-height: 3.4rem; }
    #details_container .details-sidebar .price.sale {
      color: #dc371c; }
    #details_container .details-sidebar .price.standardPrice {
      font-size: 1.6rem;
      font-weight: normal;
      color: #575757; }
    #details_container .details-sidebar .price .t-d-line {
      text-decoration: line-through; }
  #details_container .details-sidebar .price-sub {
    font-weight: bold; }
    #details_container .details-sidebar .price-sub .unit-string {
      font-weight: normal; }
  #details_container .details-sidebar .offerDuration {
    font-size: 1.2rem;
    font-style: italic;
    margin-top: 1rem; }
  #details_container .details-sidebar .vat-box {
    font-style: italic; }
  #details_container .details-sidebar .variant-selection {
    padding-top: 3rem; }
    #details_container .details-sidebar .variant-selection .bootstrap-select {
      width: 100%;
      margin-bottom: 1.5rem; }
    #details_container .details-sidebar .variant-selection.highlighted button.dropdown-toggle.highlighted {
      border: 1px solid #dc371c; }
    #details_container .details-sidebar .variant-selection.highlighted label.highlighted {
      color: #dc371c; }
    #details_container .details-sidebar .variant-selection .variant-selection-label-row {
      display: flex;
      gap: 1rem;
      align-items: center;
      align-content: center;
      line-height: 1;
      height: 2.5rem; }
      #details_container .details-sidebar .variant-selection .variant-selection-label-row.color, #details_container .details-sidebar .variant-selection .variant-selection-label-row.button-s, #details_container .details-sidebar .variant-selection .variant-selection-label-row.button-m, #details_container .details-sidebar .variant-selection .variant-selection-label-row.thumb {
        border-bottom: 1px solid #e3e3e3;
        margin-bottom: .5rem; }
      #details_container .details-sidebar .variant-selection .variant-selection-label-row > * {
        padding: 0; }
    #details_container .details-sidebar .variant-selection .variant-selection-tooltip {
      cursor: default;
      font-size: 1rem;
      display: block;
      float: right;
      vertical-align: middle;
      position: relative;
      padding-left: .5rem;
      margin-left: auto; }
      #details_container .details-sidebar .variant-selection .variant-selection-tooltip.selection-modal-open {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background-color: rgba(87, 87, 87, 0.5);
        overflow: hidden; }
        #details_container .details-sidebar .variant-selection .variant-selection-tooltip.selection-modal-open i.variant-selection-icon {
          display: none; }
      #details_container .details-sidebar .variant-selection .variant-selection-tooltip i.variant-selection-icon {
        display: inline-block;
        font-size: 1.8rem;
        vertical-align: middle; }
      #details_container .details-sidebar .variant-selection .variant-selection-tooltip .variant-selection-tooltip-outer {
        position: absolute;
        right: 0;
        width: 20rem;
        height: auto;
        z-index: 1000; }
        #details_container .details-sidebar .variant-selection .variant-selection-tooltip .variant-selection-tooltip-outer .variant-selection-tooltip-triangle {
          width: 0;
          height: 0;
          border-left: .5rem solid transparent;
          border-right: .5rem solid transparent;
          border-bottom: 0.5rem solid #575757;
          display: none;
          margin: 0 .45rem 0 auto;
          z-index: 1000; }
        #details_container .details-sidebar .variant-selection .variant-selection-tooltip .variant-selection-tooltip-outer .variant-selection-tooltip-inner {
          text-align: center;
          overflow: hidden; }
          #details_container .details-sidebar .variant-selection .variant-selection-tooltip .variant-selection-tooltip-outer .variant-selection-tooltip-inner.desktop {
            position: relative;
            right: 0;
            width: 20rem;
            height: auto;
            font-size: 1.2rem;
            padding: 1rem;
            background-color: #FFFFFF;
            border: 1px solid #aeaeae;
            color: #575757;
            z-index: 1500;
            display: none;
            border-radius: .2rem; }
          #details_container .details-sidebar .variant-selection .variant-selection-tooltip .variant-selection-tooltip-outer .variant-selection-tooltip-inner.mobile-modal {
            position: fixed;
            left: 1rem;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
            height: auto;
            font-size: 1.2rem;
            padding: 1rem;
            background-color: #FFFFFF;
            border: 1px solid #aeaeae;
            z-index: 1500;
            display: block;
            border-radius: .2rem; }
          #details_container .details-sidebar .variant-selection .variant-selection-tooltip .variant-selection-tooltip-outer .variant-selection-tooltip-inner .close {
            position: absolute;
            right: .5rem;
            top: .5rem; }
      @media (min-width: 768px) {
        #details_container .details-sidebar .variant-selection .variant-selection-tooltip:hover .variant-selection-tooltip-inner.desktop, #details_container .details-sidebar .variant-selection .variant-selection-tooltip:hover .variant-selection-tooltip-triangle {
          transition: all .3s;
          display: block; } }
    #details_container .details-sidebar .variant-selection .show-less {
      padding: 0.5rem;
      margin-top: -2rem;
      margin-bottom: 2rem; }
      #details_container .details-sidebar .variant-selection .show-less div {
        width: 100%;
        margin: auto;
        background-color: #FFFFFF;
        border: 1px solid #aeaeae;
        text-align: center;
        margin: auto;
        color: #575757;
        font-size: 1.5rem;
        cursor: pointer; }
  #details_container .details-sidebar .active-varsel {
    font-weight: 400;
    font-size: 1.1rem; }
  #details_container .details-sidebar .custom-variant-selection {
    margin-bottom: 2rem; }
    #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item {
      padding: .5rem;
      margin: 0;
      overflow: hidden; }
      #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.thumb img {
        border: 1px solid #aeaeae;
        cursor: pointer; }
      #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.thumb.selected img {
        border: 2px solid #94c947; }
      #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.thumb.disabled {
        order: 998 !important; }
      #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.thumb.show-less {
        order: 1000; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.thumb.show-less .inner {
          width: 100%;
          height: 100%;
          border: 1px solid transparent;
          position: relative;
          background-color: #FFFFFF;
          cursor: pointer;
          text-align: center; }
      #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.thumb.show-more {
        order: 999; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.thumb.show-more .inner {
          width: 100%;
          height: 100%;
          border: 1px solid #aeaeae;
          position: relative;
          background-color: #FFFFFF;
          cursor: pointer; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.thumb.show-more span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-weight: 700;
          cursor: pointer; }
      #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.button {
        text-align: center;
        padding: .5rem;
        margin: 0;
        font-weight: 400;
        cursor: pointer; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.button .inner {
          border: 1px solid #aeaeae;
          background-color: #FFFFFF;
          min-height: 4rem;
          height: 100%;
          line-height: 1;
          vertical-align: middle;
          position: relative;
          display: flex;
          align-content: center;
          align-items: center;
          justify-content: center;
          justify-items: center;
          padding: 0.25rem; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.button.selected .inner {
          border: 3px solid #94c947; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.button.disabled {
          order: 998 !important; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.button.show-more {
          order: 999;
          font-weight: bold; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.button.show-less {
          order: 1000; }
          #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.button.show-less .inner {
            width: 100%;
            height: 100%;
            border: 1px solid transparent;
            position: relative;
            background-color: #FFFFFF;
            cursor: pointer;
            text-align: center; }
      #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color {
        height: 4.5rem;
        width: 4.5rem;
        cursor: pointer;
        position: relative; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color .inner {
          height: 100%;
          margin: 0 auto;
          border: 1px solid #aeaeae;
          border-radius: 50%;
          outline: 2px solid transparent;
          outline-offset: 2px;
          transition: outline .1s ease-in-out;
          overflow: hidden;
          display: flex;
          flex-direction: row;
          align-content: center;
          flex-wrap: wrap;
          rotate: 20deg; }
          #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color .inner:hover {
            outline: 2px solid #aeaeae;
            outline-offset: 2px; }
          #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color .inner:first-of-type div {
            width: 100%;
            height: 100%;
            align-self: flex-start; }
          #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color .inner:last-of-type div {
            width: 100%;
            height: 100%;
            align-self: flex-end; }
          #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color .inner .color_gold {
            background: linear-gradient(135deg, #fceabb, #fccd4d 50%, #f8b500 51%, #fbdf93); }
          #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color .inner .color_silber {
            background: linear-gradient(135deg, #f5f6f6, #dbdce2 21%, #b8bac6 49%, #dddfe3 80%, #f5f6f6); }
          #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color .inner .color_transparent {
            background: linear-gradient(135deg, #e1ffff, #e1ffff 7%, #e1ffff 12%, #fdffff 0, #e6f8fd 30%, #c8eefb 54%, #bee4f8 75%, #b1d8f5); }
          #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color .inner .color_multi {
            background: linear-gradient(90deg, #b43a3a 0%, #afa330 20%, #e2e323 40%, #1dfdca 60%, #432ffd 80%, #fc45c4 100%); }
          #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color .inner .color {
            width: 100%;
            height: 100%; }
            #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color .inner .color.no-color {
              border: 1px solid #e3e3e3;
              display: flex;
              justify-items: center;
              justify-content: center;
              align-content: center;
              align-items: center;
              rotate: -20deg; }
          #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color .inner .custom-variant-disabled-layer {
            rotate: -20deg; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color.selected .inner {
          outline: 2px solid #94c947;
          outline-offset: 2px; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color.disabled .no-color {
          display: none !important; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.color .custom-variant-disabled-layer i {
          font-size: 1.5rem; }
      #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.disabled {
        cursor: not-allowed; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.disabled .inner {
          background-color: #e3e3e3; }
          #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.disabled .inner span {
            cursor: not-allowed;
            opacity: .5; }
        #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item.disabled img {
          cursor: not-allowed;
          opacity: .5; }
      #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item .custom-variant-highlighted-selection {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #dc371c;
        border: 1px solid #dc371c;
        height: 1.4rem;
        width: auto;
        font-size: 1rem;
        line-height: 1.2rem;
        color: #FFFFFF;
        padding: 0 .3rem;
        text-align: center;
        font-weight: 700; }
      #details_container .details-sidebar .custom-variant-selection .custom-variant-selection-item .custom-variant-disabled-layer {
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
        font-weight: bold;
        color: #000000; }
  #details_container .details-sidebar .to-variants-btn {
    margin: 1rem 0;
    width: 100%; }
  #details_container .details-sidebar .reset-variants-box {
    margin-top: -1rem; }
    #details_container .details-sidebar .reset-variants-box .ra-details-reset-variants-button, #details_container .details-sidebar .reset-variants-box .ra-details-reset-variants-button-ie {
      display: none;
      background-color: transparent;
      border: none;
      font-size: 1.2rem;
      color: #575757;
      cursor: pointer; }
      #details_container .details-sidebar .reset-variants-box .ra-details-reset-variants-button .ra-icon, #details_container .details-sidebar .reset-variants-box .ra-details-reset-variants-button-ie .ra-icon {
        font-size: 1.5rem; }
      #details_container .details-sidebar .reset-variants-box .ra-details-reset-variants-button:hover, #details_container .details-sidebar .reset-variants-box .ra-details-reset-variants-button-ie:hover {
        color: #171717; }
        #details_container .details-sidebar .reset-variants-box .ra-details-reset-variants-button:hover .ra-icon, #details_container .details-sidebar .reset-variants-box .ra-details-reset-variants-button-ie:hover .ra-icon {
          color: #171717; }
      #details_container .details-sidebar .reset-variants-box .ra-details-reset-variants-button.visible, #details_container .details-sidebar .reset-variants-box .ra-details-reset-variants-button-ie.visible {
        display: inline-block; }
  #details_container .details-sidebar .ra-details-delivery-container {
    margin: 2rem 0 1rem 0; }
    #details_container .details-sidebar .ra-details-delivery-container .ra-in-stock-info {
      color: #FFFFFF;
      font-weight: bold;
      padding: 0 .5rem; }
    #details_container .details-sidebar .ra-details-delivery-container .ra-details-delivery {
      font-weight: bold; }
      @media (max-width: 767.98px) {
        #details_container .details-sidebar .ra-details-delivery-container .ra-details-delivery {
          padding-left: 0; } }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-delivery .ra-icon {
        font-size: 1.5rem;
        margin-top: .2rem; }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-delivery .t-confirm {
        color: #307f35; }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-delivery .express-delivery {
        display: block;
        font-weight: normal;
        font-style: italic; }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-delivery .variant-info {
        font-weight: 400; }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-delivery .ra-details-delivery-pickup-only span {
        font-size: 1.6rem; }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-delivery .ra-details-delivery-pickup-only p {
        font-weight: 400; }
    #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-bulky-xxl, #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-bulky, #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-longitem {
      padding: 0; }
      @media (min-width: 768px) {
        #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-bulky-xxl:nth-child(odd), #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-bulky:nth-child(odd), #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-longitem:nth-child(odd) {
          padding-right: 1rem; }
        #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-bulky-xxl:nth-child(even), #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-bulky:nth-child(even), #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-longitem:nth-child(even) {
          padding-left: 1rem; } }
    #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item {
      display: flex;
      justify-content: center;
      justify-items: center;
      margin-top: 1.5rem;
      text-align: center;
      border: 2px solid #aeaeae;
      border-radius: .5rem;
      position: relative; }
      @media (max-width: 767.98px) {
        #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item {
          margin-top: 3rem; } }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .udw-special-item-active-info-icon {
        display: none;
        position: absolute;
        top: -1rem;
        left: -1rem;
        background-color: #f6f6f6;
        font-weight: 400;
        width: 2rem;
        text-align: center; }
        @media (max-width: 767.98px) {
          #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .udw-special-item-active-info-icon {
            background-color: #FFFFFF; } }
        #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .udw-special-item-active-info-icon .ra-icon.check-circle {
          color: #308136;
          font-size: 1.5rem;
          vertical-align: middle; }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item.udw-special-item-active {
        border: 2px solid #308136; }
        #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item.udw-special-item-active .udw-special-item-active-info-icon {
          display: inline-block; }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item.udw-special-item-inactive .ra-details-special-item-body, #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item.udw-special-item-inactive .ra-details-special-item-header * {
        opacity: .4; }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .ra-details-special-item-header {
        transform: translateY(-50%);
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        margin: auto;
        padding: 0 .5rem;
        background-color: #f6f6f6; }
        @media (max-width: 767.98px) {
          #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .ra-details-special-item-header {
            background-color: #FFFFFF; } }
        #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .ra-details-special-item-header i {
          font-size: 2.9rem;
          vertical-align: middle; }
        #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .ra-details-special-item-header .bulky-info, #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .ra-details-special-item-header .bulky-xxl-info, #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .ra-details-special-item-header .longitem-info {
          font-weight: 700;
          margin-left: .5rem;
          font-size: 1.2rem; }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .ra-details-special-item-body {
        margin-top: 1rem;
        padding: .5rem;
        font-size: 1.2rem; }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .bulky-tooltip, #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .bulky-xxl-tooltip, #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .longitem-tooltip {
        position: absolute;
        top: -1rem;
        right: -1rem;
        background-color: #f6f6f6; }
        @media (max-width: 767.98px) {
          #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .bulky-tooltip, #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .bulky-xxl-tooltip, #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .longitem-tooltip {
            background-color: #FFFFFF; } }
      #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .bulky-rule, #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .bulky-xxl-rule, #details_container .details-sidebar .ra-details-delivery-container .ra-details-special-items .ra-details-special-item .longitem-rule {
        margin-top: .5rem;
        font-size: 1.2rem;
        line-height: 1.4rem;
        font-weight: 400;
        font-style: italic; }
  #details_container .details-sidebar .ra-details-article-question-button {
    font-weight: bold;
    width: 100%;
    height: 3rem !important;
    min-height: 3rem !important;
    line-height: 2rem !important;
    font-size: 1.2rem !important; }
    #details_container .details-sidebar .ra-details-article-question-button .ra-icon {
      font-size: 2.9rem;
      vertical-align: text-top;
      margin-top: -.7rem; }
  #details_container .details-sidebar .ra-details-article-leasing {
    margin-top: 1rem; }
    #details_container .details-sidebar .ra-details-article-leasing .ra-details-article-leasing-button {
      text-decoration: underline;
      cursor: pointer; }
      #details_container .details-sidebar .ra-details-article-leasing .ra-details-article-leasing-button:hover {
        color: #94c947; }
  #details_container .details-sidebar .ra-details-price-box-attributes {
    margin-top: 2rem; }
    #details_container .details-sidebar .ra-details-price-box-attributes table tr td:last-child {
      width: 100%; }
  #details_container .details-sidebar .ra-details-accessories-button {
    width: 100%;
    margin-top: 3rem; }
  #details_container .details-sidebar .custom_details_additional_payment_buttons-wrapper {
    display: inline-block;
    width: 100%;
    text-align: center; }
    #details_container .details-sidebar .custom_details_additional_payment_buttons-wrapper.two-columns .paypalExpressCheckoutDetailsBox {
      width: auto; }
  #details_container .details-sidebar .ra-details-priceonrequest-button {
    font-weight: bold;
    width: 100%;
    height: auto;
    margin-top: 4rem; }
  #details_container .details-sidebar hr.middle-text:after {
    background-color: #f6f6f6; }
    @media (max-width: 767.98px) {
      #details_container .details-sidebar hr.middle-text:after {
        background-color: #FFFFFF; } }
  #details_container .details-sidebar .additional-buttons {
    margin-top: 1rem; }
    #details_container .details-sidebar .additional-buttons div {
      padding: 0; }
      @media (max-width: 991.98px) {
        #details_container .details-sidebar .additional-buttons div {
          padding-top: .5rem;
          text-align: left !important; } }
  #details_container .details-sidebar .detailsFreeShippingInfo {
    color: #dc371c; }
    #details_container .details-sidebar .detailsFreeShippingInfo .ra-icon {
      padding-top: 1rem;
      font-size: 2.9rem;
      vertical-align: text-top;
      margin-top: -1.5rem;
      font-weight: normal; }
  #details_container .details-sidebar .ra-details-action-bar {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-evenly;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
    margin: 1.5rem 0 0 0; }
    #details_container .details-sidebar .ra-details-action-bar button {
      background-color: transparent;
      border: none;
      color: #575757; }
    #details_container .details-sidebar .ra-details-action-bar > span, #details_container .details-sidebar .ra-details-action-bar a, #details_container .details-sidebar .ra-details-action-bar button {
      height: 3rem !important; }
    #details_container .details-sidebar .ra-details-action-bar span, #details_container .details-sidebar .ra-details-action-bar a, #details_container .details-sidebar .ra-details-action-bar button {
      display: flex;
      align-content: center;
      align-items: center;
      justify-items: center;
      justify-content: center;
      -moz-column-gap: 1rem;
           column-gap: 1rem;
      cursor: pointer;
      margin-top: 0 !important;
      font-size: 1.2rem; }
      #details_container .details-sidebar .ra-details-action-bar span:hover, #details_container .details-sidebar .ra-details-action-bar a:hover, #details_container .details-sidebar .ra-details-action-bar button:hover {
        color: #94c947; }
      #details_container .details-sidebar .ra-details-action-bar span.btn, #details_container .details-sidebar .ra-details-action-bar a.btn, #details_container .details-sidebar .ra-details-action-bar button.btn {
        width: 100%;
        height: 4rem;
        background-color: #FFFFFF;
        border: none !important; }
      #details_container .details-sidebar .ra-details-action-bar span .ra-icon, #details_container .details-sidebar .ra-details-action-bar a .ra-icon, #details_container .details-sidebar .ra-details-action-bar button .ra-icon {
        font-size: 2.4rem;
        vertical-align: middle; }
  @media (max-width: 767.98px) {
    #details_container .details-sidebar .ra-details-action-bar {
      display: none !important; } }
  #details_container .details-sidebar .ra-details-info-snippet {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  #details_container .details-sidebar .standard-price-box {
    opacity: 1;
    transition: .3s all;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent; }
    #details_container .details-sidebar .standard-price-box .udws-heading {
      transition: .3s all;
      opacity: 0; }
    #details_container .details-sidebar .standard-price-box.disabled-box {
      opacity: .4; }
      #details_container .details-sidebar .standard-price-box.disabled-box .udws-heading {
        opacity: .4; }
    #details_container .details-sidebar .standard-price-box.price-box-right {
      margin-top: 1rem;
      text-align: right; }
  #details_container .details-sidebar .udws-price-box-outer {
    position: relative;
    border-left: 1px solid #aeaeae;
    border-right: 1px solid transparent; }
    #details_container .details-sidebar .udws-price-box-outer .udws-price-box, #details_container .details-sidebar .udws-price-box-outer h4 {
      padding-left: 2rem; }
    #details_container .details-sidebar .udws-price-box-outer .spinner {
      position: absolute;
      top: -1rem;
      width: 50%; }
    #details_container .details-sidebar .udws-price-box-outer.price-box-right {
      margin-top: 1rem;
      text-align: right;
      border-left: 1px solid transparent;
      border-right: 1px solid #aeaeae; }
      #details_container .details-sidebar .udws-price-box-outer.price-box-right .udws-price-box, #details_container .details-sidebar .udws-price-box-outer.price-box-right h4 {
        padding-left: 0;
        padding-right: 2rem; }
  #details_container .details-sidebar .filter-option-inner-inner div.variant-selection-ribbon {
    display: none; }
  #details_container .details-sidebar .dropdown-menu {
    max-width: 100%;
    overflow: hidden; }
    #details_container .details-sidebar .dropdown-menu .dropdown-item span.text {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle; }
    #details_container .details-sidebar .dropdown-menu .highlighted span.text {
      display: flex;
      width: 100%; }
      #details_container .details-sidebar .dropdown-menu .highlighted span.text span {
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis; }
      #details_container .details-sidebar .dropdown-menu .highlighted span.text div.variant-selection-ribbon {
        flex: 1 1 auto;
        -moz-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        -webkit-flex: 1 1 auto;
        color: #dc371c;
        font-weight: bold;
        margin-left: .5rem;
        text-align: right; }
  #details_container .details-sidebar .ra-details-comment-and-cost-centre {
    margin-top: 2rem;
    width: 100%; }
    #details_container .details-sidebar .ra-details-comment-and-cost-centre label {
      width: 100%;
      display: block; }
    #details_container .details-sidebar .ra-details-comment-and-cost-centre textarea {
      display: block;
      width: 100%;
      height: 6rem !important;
      border: 1px solid #aeaeae; }
      #details_container .details-sidebar .ra-details-comment-and-cost-centre textarea:focus {
        outline: none; }
  #details_container .details-sidebar .ra-details-img-ribbons {
    margin-top: 2rem; }
    #details_container .details-sidebar .ra-details-img-ribbons.deliveryrequest {
      margin-bottom: 2rem; }
    #details_container .details-sidebar .ra-details-img-ribbons .img-ribbon {
      position: relative;
      margin: 0 auto 2rem auto; }
      #details_container .details-sidebar .ra-details-img-ribbons .img-ribbon .img-ribbon-tooltip {
        padding-top: .5rem;
        position: absolute;
        z-index: 1000; }
        #details_container .details-sidebar .ra-details-img-ribbons .img-ribbon .img-ribbon-tooltip .img-ribbon-tooltip-triangle {
          width: 0;
          height: 0;
          border-left: .5rem solid transparent;
          border-right: .5rem solid transparent;
          border-bottom: 0.5rem solid #575757;
          margin-left: 4.5rem;
          display: none;
          z-index: 998; }
        #details_container .details-sidebar .ra-details-img-ribbons .img-ribbon .img-ribbon-tooltip .img-ribbon-tooltip-inner {
          position: relative;
          width: 30rem;
          height: auto;
          font-size: 1.2rem;
          padding: 1rem;
          background-color: #FFFFFF;
          border: 1px solid #aeaeae;
          color: #575757;
          z-index: 998;
          transform: translateX(-9rem);
          display: none;
          border-radius: .2rem; }
    @media (min-width: 992px) {
      #details_container .details-sidebar .ra-details-img-ribbons .img-ribbon:hover .img-ribbon-tooltip-inner, #details_container .details-sidebar .ra-details-img-ribbons .img-ribbon:hover .img-ribbon-tooltip-triangle, #details_container .details-sidebar .ra-details-img-ribbons .img-ribbon-tooltip:hover .img-ribbon-tooltip-inner, #details_container .details-sidebar .ra-details-img-ribbons .img-ribbon-tooltip:hover .img-ribbon-tooltip-triangle {
        transition: all .3s;
        display: block; } }
  #details_container .details-sidebar .ra-details-img-productsticker {
    margin-top: 2rem; }
    #details_container .details-sidebar .ra-details-img-productsticker .img-productsticker {
      position: relative;
      margin: 0 auto 1rem;
      padding: 0; }
      #details_container .details-sidebar .ra-details-img-productsticker .img-productsticker .img-productsticker-tooltip {
        padding-top: .5rem;
        position: absolute;
        z-index: 1000; }
        #details_container .details-sidebar .ra-details-img-productsticker .img-productsticker .img-productsticker-tooltip .img-productsticker-tooltip-triangle {
          width: 0;
          height: 0;
          border-left: .5rem solid transparent;
          border-right: .5rem solid transparent;
          border-bottom: 0.5rem solid #575757;
          margin-left: 4.5rem;
          display: none;
          z-index: 998; }
        #details_container .details-sidebar .ra-details-img-productsticker .img-productsticker .img-productsticker-tooltip .img-productsticker-tooltip-inner {
          position: relative;
          width: 30rem;
          height: auto;
          font-size: 1.2rem;
          padding: 1rem;
          background-color: #FFFFFF;
          border: 1px solid #aeaeae;
          color: #575757;
          z-index: 998;
          transform: translateX(-9rem);
          display: none;
          border-radius: .2rem; }
    @media (min-width: 992px) {
      #details_container .details-sidebar .ra-details-img-productsticker .img-productsticker:hover .img-productsticker-tooltip-inner, #details_container .details-sidebar .ra-details-img-productsticker .img-productsticker:hover .img-productsticker-tooltip-triangle, #details_container .details-sidebar .ra-details-img-productsticker .img-productsticker-tooltip:hover .img-productsticker-tooltip-inner, #details_container .details-sidebar .ra-details-img-productsticker .img-productsticker-tooltip:hover .img-productsticker-tooltip-triangle {
        transition: all .3s;
        display: block; } }
  #details_container .details-sidebar .stockFlag.notOnStock {
    margin-bottom: -3rem; }
  #details_container .details-sidebar .ra-details-mandatory-accessories {
    display: flex;
    flex-direction: column;
    margin-top: 2rem; }
    #details_container .details-sidebar .ra-details-mandatory-accessories .ra-details-mandatory-accessories-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-content: center;
      align-items: center; }
      #details_container .details-sidebar .ra-details-mandatory-accessories .ra-details-mandatory-accessories-item .price-col {
        margin-left: auto;
        text-align: right; }
    #details_container .details-sidebar .ra-details-mandatory-accessories .middle-text:before {
      background-color: #575757; }
  #details_container .details-sidebar .mobile-price-col {
    padding: 0 !important; }
  #details_container .details-sidebar .variant-mobile-box.hide .variant-mobile-box-blur {
    opacity: 0; }
  #details_container .details-sidebar .variant-mobile-box.hide .variant-mobile-box-content {
    animation: hide-variant-mobile-box .5s linear forwards !important; }

@keyframes show-variant-mobile-box {
  0% {
    bottom: -100vh; }
  100% {
    bottom: 0; } }

@keyframes hide-variant-mobile-box {
  0% {
    bottom: 0; }
  100% {
    bottom: -100vh; } }
  #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur {
    transition: .5s all;
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 2147483647; }
    #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content {
      animation: show-variant-mobile-box .5s linear forwards;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #FFFFFF;
      border-radius: 2rem 2rem 0 0;
      box-shadow: 0 0 1rem #575757;
      overflow: hidden; }
      #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-topbar {
        cursor: pointer;
        padding: .5rem; }
        #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-topbar:hover {
          background: linear-gradient(180deg, #f6f6f6 0%, #FFFFFF 100%); }
          #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-topbar:hover .ra-icon {
            font-weight: bold; }
      #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner {
        padding: 1rem; }
        #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner h4 {
          margin-top: -.5rem; }
        #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .standard-price-box {
          margin-top: -1rem;
          pointer-events: none; }
          #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .standard-price-box .price {
            font-size: 1.6rem; }
          #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .standard-price-box .price-sub {
            font-size: 1.2rem; }
          #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .standard-price-box .vat-box {
            display: none; }
        #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .spinner {
          margin: 1rem auto; }
        #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .variant-mobile-box-table-outer {
          max-height: 60vh;
          overflow-y: auto;
          border-top: 1px solid #e3e3e3;
          margin-top: .5rem; }
        #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .variant-mobile-box-disabled {
          cursor: not-allowed;
          background-color: #f6f6f6;
          color: #e3e3e3; }
          #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .variant-mobile-box-disabled label {
            cursor: not-allowed;
            margin-top: -.7rem; }
          #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .variant-mobile-box-disabled small {
            display: block;
            margin-top: -.5rem;
            margin-left: 1rem;
            color: #747474;
            font-style: italic; }
          #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .variant-mobile-box-disabled .form-control:disabled {
            cursor: not-allowed;
            background-color: #f6f6f6; }
        #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .variant-mobile-box-table-variant-name {
          width: 100%; }
          #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .variant-mobile-box-table-variant-name label {
            display: flex;
            height: 3rem;
            align-items: center; }
        #details_container .details-sidebar .variant-mobile-box .variant-mobile-box-blur .variant-mobile-box-content .variant-mobile-box-inner .variant-mobile-box-table-ribbon-col label {
          color: #dc371c;
          font-weight: bold;
          display: flex;
          height: 3rem;
          align-items: center;
          text-align: center; }
  #details_container .details-sidebar .ra-details-addon-articles {
    display: flex;
    flex-direction: column;
    gap: 1rem; }
    #details_container .details-sidebar .ra-details-addon-articles > div {
      padding: 0; }
    #details_container .details-sidebar .ra-details-addon-articles > div {
      padding: 0; }
    #details_container .details-sidebar .ra-details-addon-articles .middle-text:before {
      background-color: #575757; }
    #details_container .details-sidebar .ra-details-addon-articles hr:not(.middle-text) {
      border-top: 1px solid rgba(87, 87, 87, 0.5); }
    #details_container .details-sidebar .ra-details-addon-articles .ra-details-addon-article-selection-headline {
      display: flex; }
    #details_container .details-sidebar .ra-details-addon-articles .ra-details-addon-article-selection-inner {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: flex-end;
      gap: .5rem;
      font-weight: 400;
      width: 100%;
      margin-bottom: 1rem;
      cursor: pointer; }
      #details_container .details-sidebar .ra-details-addon-articles .ra-details-addon-article-selection-inner > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: .5rem; }
      #details_container .details-sidebar .ra-details-addon-articles .ra-details-addon-article-selection-inner .ra-details-addon-article-info {
        margin-left: auto; }
        #details_container .details-sidebar .ra-details-addon-articles .ra-details-addon-article-selection-inner .ra-details-addon-article-info > span {
          display: flex;
          flex-direction: row; }
  #details_container .details-sidebar .ra-details-refinement {
    display: flex;
    flex-direction: column;
    gap: 1rem; }
    #details_container .details-sidebar .ra-details-refinement > div {
      padding: 0; }
    #details_container .details-sidebar .ra-details-refinement .middle-text:before {
      background-color: #575757; }
    #details_container .details-sidebar .ra-details-refinement hr:not(.middle-text) {
      border-top: 1px solid rgba(87, 87, 87, 0.5); }
    #details_container .details-sidebar .ra-details-refinement .ra-details-refinement-selection-headline {
      display: flex; }
    #details_container .details-sidebar .ra-details-refinement .ra-details-refinement-selection, #details_container .details-sidebar .ra-details-refinement .ra-details-refinement-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start; }
    #details_container .details-sidebar .ra-details-refinement .ra-details-refinement-selection-inner {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: flex-end;
      gap: .5rem;
      font-weight: 400;
      width: 100%;
      margin-bottom: 1rem; }
      #details_container .details-sidebar .ra-details-refinement .ra-details-refinement-selection-inner > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start; }
      #details_container .details-sidebar .ra-details-refinement .ra-details-refinement-selection-inner .ra-details-refinement-info {
        display: flex;
        flex-direction: row;
        margin-left: auto; }
    #details_container .details-sidebar .ra-details-refinement .ra-refinement-mandatory-accessories .ra-details-mandatory-accessories-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 1rem; }
      #details_container .details-sidebar .ra-details-refinement .ra-refinement-mandatory-accessories .ra-details-mandatory-accessories-item .price-col {
        margin-left: auto;
        text-align: right; }
  #details_container .details-sidebar .ra-details-refinement-selection-single, #details_container .details-sidebar .ra-details-addon-article-selection-single {
    display: flex;
    gap: 1rem;
    width: 100%; }
    #details_container .details-sidebar .ra-details-refinement-selection-single .single-switch-text, #details_container .details-sidebar .ra-details-refinement-selection-single .single-switch-text, #details_container .details-sidebar .ra-details-addon-article-selection-single .single-switch-text, #details_container .details-sidebar .ra-details-addon-article-selection-single .single-switch-text {
      line-height: 1.1;
      width: 100%;
      font-weight: 700; }
    #details_container .details-sidebar .ra-details-refinement-selection-single .single-switch, #details_container .details-sidebar .ra-details-refinement-selection-single .single-switch, #details_container .details-sidebar .ra-details-addon-article-selection-single .single-switch, #details_container .details-sidebar .ra-details-addon-article-selection-single .single-switch {
      background-color: #e3e3e3;
      width: 40px;
      height: 21px;
      border-radius: 16px;
      display: flex;
      flex-direction: column; }
      #details_container .details-sidebar .ra-details-refinement-selection-single .single-switch .single-slider, #details_container .details-sidebar .ra-details-refinement-selection-single .single-switch .single-slider, #details_container .details-sidebar .ra-details-refinement-selection-single .single-switch .single-slider, #details_container .details-sidebar .ra-details-refinement-selection-single .single-switch .single-slider, #details_container .details-sidebar .ra-details-addon-article-selection-single .single-switch .single-slider, #details_container .details-sidebar .ra-details-addon-article-selection-single .single-switch .single-slider, #details_container .details-sidebar .ra-details-addon-article-selection-single .single-switch .single-slider, #details_container .details-sidebar .ra-details-addon-article-selection-single .single-switch .single-slider {
        height: 14px;
        width: 14px;
        background-color: white;
        border-radius: 50%;
        margin: 4px; }
    #details_container .details-sidebar .ra-details-refinement-selection-single input, #details_container .details-sidebar .ra-details-addon-article-selection-single input {
      display: none; }
    #details_container .details-sidebar .ra-details-refinement-selection-single input:checked + div > .single-switch, #details_container .details-sidebar .ra-details-refinement-selection-single input:checked + div > .single-switch, #details_container .details-sidebar .ra-details-addon-article-selection-single input:checked + div > .single-switch, #details_container .details-sidebar .ra-details-addon-article-selection-single input:checked + div > .single-switch {
      background-color: #94c947; }
    #details_container .details-sidebar .ra-details-refinement-selection-single input:checked + div > .single-switch > .single-slider, #details_container .details-sidebar .ra-details-refinement-selection-single input:checked + div > .single-switch > .single-slider, #details_container .details-sidebar .ra-details-addon-article-selection-single input:checked + div > .single-switch > .single-slider, #details_container .details-sidebar .ra-details-addon-article-selection-single input:checked + div > .single-switch > .single-slider {
      align-self: flex-end; }
  #details_container .details-sidebar .ra-details-refinement-custom-total-sum, #details_container .details-sidebar .ra-details-addon-article-custom-total-sum {
    margin-top: 2rem; }
    #details_container .details-sidebar .ra-details-refinement-custom-total-sum .text, #details_container .details-sidebar .ra-details-refinement-custom-total-sum .price, #details_container .details-sidebar .ra-details-addon-article-custom-total-sum .text, #details_container .details-sidebar .ra-details-addon-article-custom-total-sum .price {
      display: flex;
      flex-direction: column;
      justify-content: center; }
    #details_container .details-sidebar .ra-details-refinement-custom-total-sum .text, #details_container .details-sidebar .ra-details-addon-article-custom-total-sum .text {
      font-weight: 700;
      font-size: 1.6rem; }
    #details_container .details-sidebar .ra-details-refinement-custom-total-sum .price, #details_container .details-sidebar .ra-details-addon-article-custom-total-sum .price {
      text-align: right; }
  #details_container .details-sidebar .ra-article-custom-price-details {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    position: relative; }
    #details_container .details-sidebar .ra-article-custom-price-details .loading-layer {
      position: absolute;
      inset: 0;
      bottom: 2rem;
      align-items: center;
      align-content: center;
      justify-items: center;
      justify-content: center;
      display: none; }
    #details_container .details-sidebar .ra-article-custom-price-details.loading {
      opacity: .5; }
      #details_container .details-sidebar .ra-article-custom-price-details.loading .loading-layer {
        display: flex; }
    #details_container .details-sidebar .ra-article-custom-price-details .ra-article-custom-price-details-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end; }
      #details_container .details-sidebar .ra-article-custom-price-details .ra-article-custom-price-details-row .total-price-colon {
        display: none; }
      #details_container .details-sidebar .ra-article-custom-price-details .ra-article-custom-price-details-row .no-value {
        margin-right: 1rem; }
      #details_container .details-sidebar .ra-article-custom-price-details .ra-article-custom-price-details-row > span:last-child {
        padding-left: 2rem;
        margin-left: auto;
        text-align: right; }
      #details_container .details-sidebar .ra-article-custom-price-details .ra-article-custom-price-details-row:nth-last-of-type(2) {
        font-weight: 700; }
        #details_container .details-sidebar .ra-article-custom-price-details .ra-article-custom-price-details-row:nth-last-of-type(2) span:last-child {
          border-top: 1px solid #575757; }
      #details_container .details-sidebar .ra-article-custom-price-details .ra-article-custom-price-details-row:first-of-type span:last-child {
        border: none; }
      #details_container .details-sidebar .ra-article-custom-price-details .ra-article-custom-price-details-row:first-of-type.ra-article-total-price-details {
        justify-content: flex-start; }
        #details_container .details-sidebar .ra-article-custom-price-details .ra-article-custom-price-details-row:first-of-type.ra-article-total-price-details span:last-child {
          margin: 0;
          border: none;
          padding-left: 1rem; }
        #details_container .details-sidebar .ra-article-custom-price-details .ra-article-custom-price-details-row:first-of-type.ra-article-total-price-details .total-price-colon {
          display: inline-block;
          padding-left: 0 !important; }
    #details_container .details-sidebar .ra-article-custom-price-details hr {
      border-top: 1px solid rgba(87, 87, 87, 0.5); }
  #details_container .details-sidebar label[for="am"] {
    margin-top: 1rem; }
  #details_container .details-sidebar .ra-details-to-basket-group .choose-variant-container {
    display: none;
    position: absolute;
    inset: 0;
    background-color: white; }
  #details_container .details-sidebar .ra-details-to-basket-group:hover .choose-variant-container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    border: 1px solid #dc371c;
    color: #dc371c; }
  #details_container .details-sidebar .ra-details-to-basket-group .ra-details-amount, #details_container .details-sidebar .ra-details-to-basket-group .ra-details-configurator-amount, #details_container .details-sidebar .ra-details-to-basket-group .ra-details-amount-placeholder {
    background-color: #FFFFFF;
    height: 5.5rem;
    text-align: center;
    font-size: 2rem; }
  #details_container .details-sidebar .ra-details-to-basket-group .ra-details-amount-placeholder {
    border: 1px solid #aeaeae;
    width: 20%;
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    font-size: 2.5rem; }
  #details_container .details-sidebar .ra-details-to-basket-group .input-group-append {
    width: 80%; }
    #details_container .details-sidebar .ra-details-to-basket-group .input-group-append .ra-details-buy-button-tooltip-wrapper, #details_container .details-sidebar .ra-details-to-basket-group .input-group-append .ra-details-configurator-buy-button-tooltip-wrapper {
      width: 100%; }
  #details_container .details-sidebar .additional-fields-hoverable .choose-variant-input-container {
    display: none;
    position: absolute;
    background-color: white; }
  #details_container .details-sidebar .additional-fields-hoverable:hover .choose-variant-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    border: 1px solid #dc371c;
    color: #dc371c;
    text-align: center;
    margin-right: 1rem; }
    #details_container .details-sidebar .additional-fields-hoverable:hover .choose-variant-input-container .choose-variant-input-container-inner {
      padding-top: 2rem;
      padding-bottom: 2rem; }

#details_container .ra-details-usp-slider {
  margin-top: 2rem; }
  #details_container .ra-details-usp-slider.carousel {
    padding: 0;
    height: 4rem;
    cursor: default; }
    #details_container .ra-details-usp-slider.carousel ul li > * {
      display: flex;
      height: 4rem;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-content: center;
      gap: 1rem; }
    #details_container .ra-details-usp-slider.carousel ul li i {
      font-size: 2.4rem; }
    #details_container .ra-details-usp-slider.carousel ul li span {
      font-size: 1.3rem;
      letter-spacing: .05rem; }
  #details_container .ra-details-usp-slider ul {
    padding: 0;
    list-style: none;
    overflow: hidden; }
    #details_container .ra-details-usp-slider ul li:not(.carousel-item) {
      min-height: 3rem; }
      #details_container .ra-details-usp-slider ul li:not(.carousel-item) > * {
        display: flex;
        align-content: center;
        align-items: center;
        gap: 1rem; }
      #details_container .ra-details-usp-slider ul li:not(.carousel-item) i {
        font-size: 2.4rem; }
      #details_container .ra-details-usp-slider ul li:not(.carousel-item) span {
        font-size: 1.3rem;
        letter-spacing: .05rem;
        line-height: 1.3rem; }

#details_container .ra-udw-parent-info {
  width: 100%;
  margin-top: -.5rem;
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 1rem; }

#details_container .ra-udw-row {
  margin-top: 2rem; }
  #details_container .ra-udw-row.is-udw-parent-display {
    opacity: .4; }
  #details_container .ra-udw-row input::-moz-placeholder {
    color: #aeaeae; }
  #details_container .ra-udw-row input::placeholder {
    color: #aeaeae; }
  #details_container .ra-udw-row input:disabled {
    background-color: #FFFFFF;
    cursor: not-allowed; }
  #details_container .ra-udw-row .min-max-info {
    font-size: 1.2rem; }
  #details_container .ra-udw-row i.info {
    font-size: 1.4rem;
    vertical-align: middle; }
  #details_container .ra-udw-row label {
    padding-left: 0;
    font-weight: normal; }
  #details_container .ra-udw-row .input-group-text {
    font-size: 1.2rem; }
  #details_container .ra-udw-row .form-group {
    padding: 0; }
  #details_container .ra-udw-row .width-group {
    padding-left: 1rem; }
  #details_container .ra-udw-row .length-group {
    padding-right: 1rem; }
    #details_container .ra-udw-row .length-group:only-of-type {
      padding: 0; }
  #details_container .ra-udw-row .has-error label {
    color: #dc371c; }
  #details_container .ra-udw-row .has-error input {
    border: 1px solid #dc371c; }
  #details_container .ra-udw-row .has-error .error {
    color: #dc371c;
    padding-left: 1rem; }

#details_container .ra-udws-mincalculation-info {
  font-size: 1.2rem;
  padding: 0;
  margin-top: .5rem;
  display: flex;
  align-content: center;
  align-items: center;
  gap: .5rem; }
  #details_container .ra-udws-mincalculation-info i.info {
    font-size: 1.4rem;
    vertical-align: middle; }

#details_container .sticky {
  position: sticky;
  top: 2rem; }

#details_container .image-box {
  position: relative; }
  #details_container .image-box {
    width: 100%;
    position: relative; }
  #details_container .image-box ul {
    list-style: none;
    padding: 0;
    width: 99%; }
    #details_container .image-box ul li:not(:first-child) {
      margin-top: 1rem; }
    #details_container .image-box ul span {
      cursor: pointer; }
      @media (min-width: 768px) {
        #details_container .image-box ul span {
          height: 13rem;
          display: flex;
          align-items: center; }
          #details_container .image-box ul span img {
            width: 100%; } }
  #details_container .image-box .sidebar-image-box .sidebar-image-box-inner {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
    height: 50.7vw;
    max-height: 62.3rem; }
    #details_container .image-box .sidebar-image-box .sidebar-image-box-inner .scroll-btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
      color: #747474;
      background-color: #FFFFFF;
      border: 1px solid #575757;
      opacity: 0.8;
      height: 4rem;
      width: 4rem;
      display: flex;
      justify-content: center;
      justify-items: center;
      align-content: center;
      align-items: center;
      pointer-events: all;
      transition: all 100ms ease; }
      #details_container .image-box .sidebar-image-box .sidebar-image-box-inner .scroll-btn:hover {
        transition: all 100ms ease;
        color: #575757;
        opacity: 1; }
      #details_container .image-box .sidebar-image-box .sidebar-image-box-inner .scroll-btn.top {
        top: 0; }
      #details_container .image-box .sidebar-image-box .sidebar-image-box-inner .scroll-btn.bottom {
        bottom: 0; }
    #details_container .image-box .sidebar-image-box .sidebar-image-box-inner::-webkit-scrollbar {
      display: none; }
  #details_container .image-box .sidebar-image-box img {
    display: inline-block; }
  #details_container .image-box .sidebar-image-box .more-pics {
    position: relative;
    overflow: hidden; }
    #details_container .image-box .sidebar-image-box .more-pics.ar {
      padding: 1rem 0; }
      #details_container .image-box .sidebar-image-box .more-pics.ar img {
        margin: 1rem 0;
        height: 100%;
        width: auto;
        -o-object-fit: cover;
           object-fit: cover; }
    #details_container .image-box .sidebar-image-box .more-pics .anchor-overlay {
      position: absolute;
      left: 0;
      right: 0;
      height: auto;
      background-color: rgba(0, 0, 0, 0.5);
      aspect-ratio: 1 / 1; }
    #details_container .image-box .sidebar-image-box .more-pics i {
      position: absolute;
      top: 38%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 7rem;
      color: #FFFFFF; }
    #details_container .image-box .sidebar-image-box .more-pics .ra-icontext {
      position: absolute;
      left: 9%;
      top: 69%;
      color: #fff;
      font-weight: bold;
      display: block;
      line-height: 1.5rem;
      text-align: center;
      width: 9rem; }
    #details_container .image-box .sidebar-image-box .more-pics .active {
      border: 2px solid #575757; }
  @media (min-width: 768px) {
    #details_container .image-box #image-carousel .carousel-inner {
      display: flex; }
      #details_container .image-box #image-carousel .carousel-inner .carousel-item {
        align-self: center;
        align-items: center; }
        #details_container .image-box #image-carousel .carousel-inner .carousel-item.active, #details_container .image-box #image-carousel .carousel-inner .carousel-item.carousel-item-left, #details_container .image-box #image-carousel .carousel-inner .carousel-item.carousel-item-right {
          display: flex !important;
          justify-items: center; }
        #details_container .image-box #image-carousel .carousel-inner .carousel-item.carousel-item-prev, #details_container .image-box #image-carousel .carousel-inner .carousel-item.carousel-item-next {
          height: 100%; } }
  #details_container .image-box #image-carousel .carousel-inner .carousel-item {
    text-align: center;
    position: relative; }
    #details_container .image-box #image-carousel .carousel-inner .carousel-item.active {
      cursor: zoom-in; }
      #details_container .image-box #image-carousel .carousel-inner .carousel-item.active.no-image {
        cursor: default !important; }
    #details_container .image-box #image-carousel .carousel-inner .carousel-item img {
      max-height: 67rem;
      display: inline-block;
      width: 100%; }
    #details_container .image-box #image-carousel .carousel-inner .carousel-item .picDesc {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: 4rem;
      background-color: #FFFFFF;
      opacity: 0.75;
      text-align: left;
      padding: 0.5rem; }
      #details_container .image-box #image-carousel .carousel-inner .carousel-item .picDesc p {
        margin: 0;
        line-height: 1.6rem; }
        @media (max-width: 767.98px) {
          #details_container .image-box #image-carousel .carousel-inner .carousel-item .picDesc p {
            height: 3.2rem;
            overflow: hidden; } }
      #details_container .image-box #image-carousel .carousel-inner .carousel-item .picDesc .dots {
        text-align: center;
        display: block;
        width: 100%;
        font-size: 3rem;
        line-height: 0.5rem; }
    #details_container .image-box #image-carousel .carousel-inner .carousel-item .anchor-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(87, 87, 87, 0.5);
      aspect-ratio: 1 / 1; }
      #details_container .image-box #image-carousel .carousel-inner .carousel-item .anchor-overlay i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 8rem;
        color: #FFFFFF; }
  #details_container .image-box #image-carousel .carousel-indicators.toBottom {
    bottom: -3rem; }
  #details_container .image-box #image-carousel .carousel-indicators li {
    border: none;
    background-color: #747474; }
    #details_container .image-box #image-carousel .carousel-indicators li.active {
      background-color: #575757; }
  #details_container .image-box #image-carousel .carousel-control {
    transition: .3s all; }
    #details_container .image-box #image-carousel .carousel-control .ra-icon {
      margin-top: 31.6rem;
      /*@media screen and (max-width: @screen-md-max) {
        margin-top: 25.8vw;
      }*/
      font-size: 3rem;
      color: #e3e3e3;
      font-weight: bold;
      transition: .3s all; }
    #details_container .image-box #image-carousel .carousel-control.left {
      text-align: left;
      padding-left: 2rem; }
      #details_container .image-box #image-carousel .carousel-control.left:hover .ra-icon {
        color: #575757; }
    #details_container .image-box #image-carousel .carousel-control.right {
      text-align: right;
      padding-right: 2rem; }
      #details_container .image-box #image-carousel .carousel-control.right:hover .ra-icon {
        color: #575757; }

#details_container .tabs-outer {
  margin-top: 5rem; }
  @media (max-width: 991.98px) {
    #details_container .tabs-outer {
      margin-top: 2.5rem; } }
  #details_container .tabs-outer .slide-box {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none; }
    #details_container .tabs-outer .slide-box::-webkit-scrollbar {
      width: 5px;
      height: 5px; }
  #details_container .tabs-outer .tabs-bar-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f6f6f6;
    height: 6rem;
    box-shadow: 0 7px 5px rgba(100, 100, 100, 0.1);
    z-index: 997; }
  #details_container .tabs-outer .tabs-bar {
    background-color: #f6f6f6;
    height: 6rem;
    position: sticky;
    width: 100%;
    max-width: 1200px;
    z-index: 998;
    top: 0; }
    #details_container .tabs-outer .tabs-bar.invisible {
      visibility: hidden; }
      #details_container .tabs-outer .tabs-bar.invisible a {
        transition: none; }
    #details_container .tabs-outer .tabs-bar ul {
      list-style: none;
      padding: 0; }
      #details_container .tabs-outer .tabs-bar ul li {
        display: inline-block;
        padding: 0 2rem;
        border-left: 1px solid #aeaeae;
        margin: 1rem 0; }
        #details_container .tabs-outer .tabs-bar ul li:nth-child(2) {
          border-left: none; }
        #details_container .tabs-outer .tabs-bar ul li .ra-icon {
          padding: 0.1rem .5rem 0 0;
          font-size: 2rem;
          float: left; }
        #details_container .tabs-outer .tabs-bar ul li a {
          font-weight: bold;
          display: inline-block;
          height: 4rem;
          padding: 1rem 0;
          transition: .2s all;
          text-decoration: none;
          border-bottom: 1px solid transparent;
          cursor: pointer; }
          #details_container .tabs-outer .tabs-bar ul li a.active {
            border-bottom: 3px solid #94c947; }
          #details_container .tabs-outer .tabs-bar ul li a:hover:not(.active) {
            border-bottom: 3px solid #575757; }
    #details_container .tabs-outer .tabs-bar .article-tabs-info {
      text-align: right;
      font-size: 1.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 1rem; }
      #details_container .tabs-outer .tabs-bar .article-tabs-info .article-tabs-title {
        font-weight: 700; }
        #details_container .tabs-outer .tabs-bar .article-tabs-info .article-tabs-title::after {
          content: "\A";
          white-space: pre; }
    #details_container .tabs-outer .tabs-bar .article-tabs-thumb {
      display: inline-block;
      height: 4rem;
      padding: 1rem 0;
      transition: all .2s;
      text-decoration: none;
      cursor: pointer;
      float: right; }
      #details_container .tabs-outer .tabs-bar .article-tabs-thumb img {
        max-height: 4rem;
        width: auto; }
    #details_container .tabs-outer .tabs-bar .ra-details-to-basket-group .choose-variant-container {
      margin: auto;
      height: 4rem;
      display: none;
      position: absolute;
      inset: 0;
      background-color: white; }
    #details_container .tabs-outer .tabs-bar .ra-details-to-basket-group:hover .choose-variant-container {
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      border: 1px solid #dc371c;
      color: #dc371c; }
    #details_container .tabs-outer .tabs-bar .ra-details-to-basket-group .ra-details-amount, #details_container .tabs-outer .tabs-bar .ra-details-to-basket-group .ra-details-configurator-amount, #details_container .tabs-outer .tabs-bar .ra-details-to-basket-group .ra-details-amount-placeholder {
      background-color: #FFFFFF;
      height: 4rem;
      text-align: center;
      font-size: 2rem;
      margin: auto; }
    #details_container .tabs-outer .tabs-bar .ra-details-to-basket-group .ra-details-amount-placeholder {
      border: 1px solid #aeaeae;
      width: 20%;
      display: inline-flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      justify-items: center;
      font-size: 2.5rem; }
    #details_container .tabs-outer .tabs-bar .ra-details-to-basket-group .input-group-append {
      width: 80%; }
      #details_container .tabs-outer .tabs-bar .ra-details-to-basket-group .input-group-append .ra-details-buy-button-tooltip-wrapper, #details_container .tabs-outer .tabs-bar .ra-details-to-basket-group .input-group-append .ra-details-configurator-buy-button-tooltip-wrapper {
        width: 100%; }
    #details_container .tabs-outer .tabs-bar .input-group .form-control {
      margin-top: 1rem;
      height: 4rem; }
    #details_container .tabs-outer .tabs-bar .input-group .input-group-append {
      width: 80%; }
      #details_container .tabs-outer .tabs-bar .input-group .input-group-append.full-width {
        width: 100%; }
      @media (max-width: 991.98px) {
        #details_container .tabs-outer .tabs-bar .input-group .input-group-append {
          width: 70%; } }
      #details_container .tabs-outer .tabs-bar .input-group .input-group-append > div {
        width: 100%; }
      #details_container .tabs-outer .tabs-bar .input-group .input-group-append .btn {
        width: 100%;
        margin-top: 1rem;
        font-weight: bold; }
        #details_container .tabs-outer .tabs-bar .input-group .input-group-append .btn i {
          font-size: 2.9rem;
          vertical-align: text-top;
          margin-top: -0.7rem; }
    #details_container .tabs-outer .tabs-bar .paddle {
      position: absolute;
      top: 0;
      display: block;
      padding: 0;
      margin: 1rem 0;
      font-size: 2rem;
      font-weight: bold;
      border: 0;
      background-color: #f6f6f6;
      color: #575757; }
      #details_container .tabs-outer .tabs-bar .paddle div {
        height: 4rem; }
        #details_container .tabs-outer .tabs-bar .paddle div i {
          vertical-align: middle;
          line-height: 4rem; }
      #details_container .tabs-outer .tabs-bar .paddle:hover {
        cursor: pointer; }
    #details_container .tabs-outer .tabs-bar .left-paddle {
      left: 0; }
      #details_container .tabs-outer .tabs-bar .left-paddle:hover {
        color: #575757; }
    #details_container .tabs-outer .tabs-bar .right-paddle {
      right: 0; }
  #details_container .tabs-outer .tab-element {
    margin-top: 5rem;
    padding-bottom: 5rem;
    border-bottom: #e3e3e3 1px solid; }
    #details_container .tabs-outer .tab-element:last-of-type {
      border-bottom: 0; }
    #details_container .tabs-outer .tab-element h2 {
      margin-top: 2rem;
      margin-bottom: 1rem; }
    #details_container .tabs-outer .tab-element .tab-anchor {
      position: absolute;
      margin-top: -7.5rem; }
    @media (max-width: 767.98px) {
      #details_container .tabs-outer .tab-element .tab-anchor {
        margin-top: -13.5rem; } }
    #details_container .tabs-outer .tab-element .variants-table tr {
      cursor: pointer; }
      #details_container .tabs-outer .tab-element .variants-table tr.new, #details_container .tabs-outer .tab-element .variants-table tr.sale {
        color: #dc371c; }
      #details_container .tabs-outer .tab-element .variants-table tr.is-selected {
        font-weight: bold; }
    #details_container .tabs-outer .tab-element .attributes-table {
      margin-top: 2rem; }
      #details_container .tabs-outer .tab-element .attributes-table tr td {
        width: 50%; }
    #details_container .tabs-outer .tab-element .attributes-list {
      margin-top: 2rem; }
      #details_container .tabs-outer .tab-element .attributes-list .icon-col {
        padding: 0 !important;
        margin-bottom: 2rem; }
        #details_container .tabs-outer .tab-element .attributes-list .icon-col div {
          width: 5rem;
          height: 5rem;
          text-align: center; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div .ra-icon {
            font-size: 4rem;
            line-height: 4.8rem;
            vertical-align: middle; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.no-border .ra-icon {
            font-size: 5rem; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div .ra-icon.made-in {
            font-size: 5rem;
            line-height: 2.4rem;
            margin-bottom: 0; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div .ra-icon.made-in-de {
            font-size: 4.8rem;
            line-height: 2.4rem;
            margin-top: 0; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div .flag-icon {
            line-height: 2.4rem;
            font-size: 1.9rem; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div .flag-icon:last-child {
            margin-top: -.5rem;
            font-size: 2rem; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.custom div.custom-svg {
            display: flex;
            height: 5rem;
            padding: .5rem;
            justify-content: center;
            align-items: center; }
            #details_container .tabs-outer .tab-element .attributes-list .icon-col div.custom div.custom-svg svg {
              margin: auto;
              height: 2.4rem;
              width: auto;
              vertical-align: middle;
              display: block; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.lightgrey {
            color: #e3e3e3; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.border-lightgrey {
            background-color: #FFFFFF;
            border: 1px solid #e3e3e3; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.lightgrey-o {
            color: #f6f6f6; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.border-lightgrey-o {
            background-color: #FFFFFF;
            border: 1px solid #f6f6f6; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.darkgrey {
            color: #575757; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.border-darkgrey {
            background-color: #FFFFFF;
            border: 1px solid #575757; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.darkgrey-o {
            color: #747474; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.border-darkgrey-o {
            background-color: #FFFFFF;
            border: 1px solid #747474; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.border {
            color: #aeaeae; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.border-border {
            background-color: #FFFFFF;
            border: 1px solid #aeaeae; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.confirm {
            color: #308136; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.border-confirm {
            background-color: #FFFFFF;
            border: 1px solid #308136; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.highlight {
            color: #94c947; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.border-highlight {
            background-color: #FFFFFF;
            border: 1px solid #94c947; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.special {
            color: #dc371c; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.border-special {
            background-color: #FFFFFF;
            border: 1px solid #dc371c; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.rating {
            color: #f59100; }
          #details_container .tabs-outer .tab-element .attributes-list .icon-col div.border-rating {
            background-color: #FFFFFF;
            border: 1px solid #f59100; }
      #details_container .tabs-outer .tab-element .attributes-list .text-col {
        padding: 0 !important;
        margin-bottom: 2rem; }
        #details_container .tabs-outer .tab-element .attributes-list .text-col .ribbon-tooltip-moreinfo {
          display: none; }
    #details_container .tabs-outer .tab-element.shortdesc {
      margin-top: 3rem;
      padding-bottom: 2rem; }
    #details_container .tabs-outer .tab-element .ra-details-img-ribbons {
      margin: 4rem 0;
      font-size: 1.2rem; }
      #details_container .tabs-outer .tab-element .ra-details-img-ribbons .img-ribbon {
        margin-bottom: 2rem; }
  #details_container .tabs-outer .ra-details-buy-button, #details_container .tabs-outer .ra-details-configurator-tab-button {
    height: 4rem !important;
    margin: 1rem 0; }
    #details_container .tabs-outer .ra-details-buy-button.btn-lightgrey, #details_container .tabs-outer .ra-details-configurator-tab-button.btn-lightgrey {
      border-left: none; }
    #details_container .tabs-outer .ra-details-buy-button:disabled, #details_container .tabs-outer .ra-details-configurator-tab-button:disabled {
      opacity: 1; }

#details_container #ra-delivery-slider, #details_container #ra-discount-slider {
  margin: 3rem 0;
  padding: 1rem; }
  #details_container #ra-delivery-slider table, #details_container #ra-discount-slider table {
    width: 100%; }
  #details_container #ra-delivery-slider .ra-slider-text, #details_container #ra-discount-slider .ra-slider-text {
    vertical-align: middle;
    text-align: left;
    font-size: 1.5rem;
    color: #94c947;
    padding: 0 .5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  #details_container #ra-delivery-slider tbody, #details_container #ra-discount-slider tbody {
    position: relative; }
  #details_container #ra-delivery-slider .ra-slider-value-row td span, #details_container #ra-discount-slider .ra-slider-value-row td span {
    transform: translateX(50%); }
  #details_container #ra-delivery-slider .ra-slider-value-row td:first-child, #details_container #ra-discount-slider .ra-slider-value-row td:first-child {
    position: absolute; }
    #details_container #ra-delivery-slider .ra-slider-value-row td:first-child span, #details_container #ra-discount-slider .ra-slider-value-row td:first-child span {
      translate: none; }
  #details_container #ra-delivery-slider .ra-slider-value-row td:last-child span, #details_container #ra-discount-slider .ra-slider-value-row td:last-child span {
    translate: none; }
  #details_container #ra-delivery-slider .ra-slider-row, #details_container #ra-discount-slider .ra-slider-row {
    height: 2.5rem;
    position: relative;
    z-index: 901; }
  #details_container #ra-delivery-slider .ra-slider-pin-row, #details_container #ra-discount-slider .ra-slider-pin-row {
    background-color: transparent;
    height: 1rem;
    z-index: 900; }
    #details_container #ra-delivery-slider .ra-slider-pin-row .ra-slider-pin, #details_container #ra-discount-slider .ra-slider-pin-row .ra-slider-pin {
      width: .5rem;
      height: 1rem;
      background-color: #aeaeae;
      margin: 0 0 0 auto;
      display: block; }
    #details_container #ra-delivery-slider .ra-slider-pin-row td, #details_container #ra-discount-slider .ra-slider-pin-row td {
      padding: 0; }
      #details_container #ra-delivery-slider .ra-slider-pin-row td:first-child .ra-slider-pin, #details_container #ra-discount-slider .ra-slider-pin-row td:first-child .ra-slider-pin {
        margin: 0 auto 0 0; }
  #details_container #ra-delivery-slider td, #details_container #ra-discount-slider td {
    position: relative;
    font-weight: bold;
    text-align: right; }
    #details_container #ra-delivery-slider td:first-child, #details_container #ra-discount-slider td:first-child {
      text-align: left; }
    #details_container #ra-delivery-slider td.ra-slider-label, #details_container #ra-discount-slider td.ra-slider-label {
      text-align: left;
      font-style: italic;
      font-weight: 400; }
  #details_container #ra-delivery-slider .progress, #details_container #ra-discount-slider .progress {
    height: 2.5rem;
    border-radius: 0;
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.45) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, 0.45) 0, rgba(255, 255, 255, 0.45) 75%, transparent 0, transparent);
    background-size: 2rem 2rem;
    line-height: 1.5; }
    #details_container #ra-delivery-slider .progress .progress-bar, #details_container #ra-discount-slider .progress .progress-bar {
      vertical-align: middle;
      text-align: center;
      font-size: 1.5rem; }
  #details_container #ra-delivery-slider.has-minorder .ra-slider-pin-row td:first-child, #details_container #ra-discount-slider.has-minorder .ra-slider-pin-row td:first-child {
    text-align: right; }
    #details_container #ra-delivery-slider.has-minorder .ra-slider-pin-row td:first-child .ra-slider-pin, #details_container #ra-discount-slider.has-minorder .ra-slider-pin-row td:first-child .ra-slider-pin {
      margin: 0 0 0 auto; }
  #details_container #ra-delivery-slider .ra-slider-minorder-info, #details_container #ra-discount-slider .ra-slider-minorder-info {
    color: #dc371c;
    font-weight: bold; }

#details_container .summary-anchor {
  padding: 0 1rem; }

#details_container .ra-details-keywords {
  margin-top: 3rem;
  padding: 0 1rem; }
  #details_container .ra-details-keywords ul {
    list-style: disc;
    padding-left: 2rem; }
  #details_container .ra-details-keywords h2 {
    display: flex;
    align-content: center;
    align-items: center;
    gap: 0 1rem;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: .5rem; }
    #details_container .ra-details-keywords h2 i {
      font-size: 2.4rem; }
  #details_container .ra-details-keywords.ramedia-accordion h2 {
    border: none;
    display: block; }
    #details_container .ra-details-keywords.ramedia-accordion h2 button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      font-size: 1.8rem; }
      #details_container .ra-details-keywords.ramedia-accordion h2 button span {
        font-size: 1.8rem;
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        align-items: center;
        align-content: center; }

#details_container .ra-download-button-big {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  background-color: #575757;
  color: #FFFFFF;
  margin-bottom: 2rem;
  padding: 1rem;
  transition: all .25s ease-in-out;
  height: 13rem; }
  #details_container .ra-download-button-big .file-info-box {
    display: grid;
    width: 100%; }
    #details_container .ra-download-button-big .file-info-box .article-title {
      position: absolute;
      bottom: 1rem;
      transition: all .5s ease-in-out;
      color: #FFFFFF;
      align-self: end;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 80%;
      overflow: hidden; }
    #details_container .ra-download-button-big .file-info-box .file-text {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 1.6rem; }
  #details_container .ra-download-button-big .download-style {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.5rem;
    transition: width .25s ease-in-out;
    padding: 0.2rem;
    width: 0; }
    #details_container .ra-download-button-big .download-style .download-icon {
      font-size: 3rem; }
    #details_container .ra-download-button-big .download-style .download-text {
      display: inline-flex;
      width: 0;
      overflow: hidden; }
      #details_container .ra-download-button-big .download-style .download-text .text-inner {
        display: inline-flex;
        transition: all .25s ease-in-out;
        justify-self: flex-end;
        align-self: center;
        text-overflow: ellipsis;
        margin: 0 .5rem;
        overflow: hidden; }
  #details_container .ra-download-button-big:hover {
    box-shadow: 0 0 5px black;
    transition: all .25s ease-in-out; }
    #details_container .ra-download-button-big:hover .file-info-box .article-title {
      transition: all .1s ease-in-out;
      color: transparent; }
    #details_container .ra-download-button-big:hover .download-style {
      transition: width .25s ease-in-out;
      width: -moz-fit-content;
      width: fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content; }
      #details_container .ra-download-button-big:hover .download-style .download-text {
        transition: width .25s ease-in-out;
        display: inline-flex;
        overflow: hidden;
        width: 100%; }
        #details_container .ra-download-button-big:hover .download-style .download-text .text-inner {
          overflow: hidden;
          display: inline-flex;
          justify-self: flex-end;
          text-overflow: ellipsis; }

#details_container .ra-details-sidebar-downloads {
  margin-top: 3rem;
  padding: 0 1rem; }
  #details_container .ra-details-sidebar-downloads h2 {
    display: flex;
    align-content: center;
    align-items: center;
    gap: 0 1rem;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: .5rem; }
    #details_container .ra-details-sidebar-downloads h2 i {
      font-size: 2.4rem; }
  #details_container .ra-details-sidebar-downloads .ra-details-download-button {
    display: flex;
    align-content: center;
    align-items: center;
    gap: 0 1rem;
    color: #dc371c;
    margin: 0 !important; }
    #details_container .ra-details-sidebar-downloads .ra-details-download-button .ra-icon {
      font-size: 2.4rem !important; }
  #details_container .ra-details-sidebar-downloads .ra-details-downloads-print-info {
    font-size: 1.2rem;
    padding-left: 3.5rem; }
    #details_container .ra-details-sidebar-downloads .ra-details-downloads-print-info .headline {
      font-weight: 700;
      margin: 0 !important; }
  #details_container .ra-details-sidebar-downloads.ramedia-accordion h2 {
    border: none;
    display: block; }
    #details_container .ra-details-sidebar-downloads.ramedia-accordion h2 button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      font-size: 1.8rem; }
      #details_container .ra-details-sidebar-downloads.ramedia-accordion h2 button span {
        font-size: 1.8rem;
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        align-items: center;
        align-content: center; }

#details_container .ra-details-downloads {
  clear: both;
  display: inline-block;
  width: 100%;
  position: relative; }
  @media (max-width: 767.98px) {
    #details_container .ra-details-downloads {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem; } }
  #details_container .ra-details-downloads.row-buttons {
    max-width: 33%; }
    @media (max-width: 991.98px) {
      #details_container .ra-details-downloads.row-buttons {
        max-width: 48%; } }
  #details_container .ra-details-downloads .ra-details-download-button {
    width: 100%;
    height: 4rem;
    font-weight: bold;
    margin-bottom: 1rem; }
    #details_container .ra-details-downloads .ra-details-download-button .ra-icon {
      font-size: 2.9rem; }

#details_container .ra-details-print-info {
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding: 0 1rem; }
  #details_container .ra-details-print-info .headline {
    font-weight: 700;
    margin-bottom: .25rem;
    color: #dc371c;
    display: flex;
    align-content: center;
    align-items: center;
    gap: 0 1rem;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: .5rem; }
    #details_container .ra-details-print-info .headline i.ra-icon {
      color: #575757;
      font-size: 2.4rem; }

#details_container .sidebar-spacer {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

#details_container h2.inline-headline {
  display: inline-block; }

#details_container .ra-udw-scale-prices {
  position: relative; }
  #details_container .ra-udw-scale-prices .spinner.loading-anim {
    display: none;
    position: absolute;
    inset: 0; }
  #details_container .ra-udw-scale-prices.loading-udw-scale-prices .headline, #details_container .ra-udw-scale-prices.loading-udw-scale-prices ul {
    opacity: 0; }
  #details_container .ra-udw-scale-prices.loading-udw-scale-prices .spinner.loading-anim {
    display: block; }

#details_container .ra-details-scale-prices, #details_container .ra-udw-scale-prices {
  margin: 1.5rem 0 0 0;
  background-color: #FFFFFF; }
  #details_container .ra-details-scale-prices.rightAlign, #details_container .ra-udw-scale-prices.rightAlign {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right !important; }
    #details_container .ra-details-scale-prices.rightAlign ul, #details_container .ra-udw-scale-prices.rightAlign ul {
      padding-bottom: 0; }
  #details_container .ra-details-scale-prices .headline, #details_container .ra-details-scale-prices .priceinfo, #details_container .ra-udw-scale-prices .headline, #details_container .ra-udw-scale-prices .priceinfo {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 1rem 1rem 0 1rem; }
  #details_container .ra-details-scale-prices ul, #details_container .ra-udw-scale-prices ul {
    list-style: none;
    padding: 0 1rem 1rem 1rem;
    display: table; }
    #details_container .ra-details-scale-prices ul li, #details_container .ra-udw-scale-prices ul li {
      display: table-row; }
      #details_container .ra-details-scale-prices ul li.special-offer, #details_container .ra-udw-scale-prices ul li.special-offer {
        color: #dc371c; }
      #details_container .ra-details-scale-prices ul li.base-price span.right, #details_container .ra-udw-scale-prices ul li.base-price span.right {
        font-weight: normal;
        font-style: italic;
        padding-left: 1rem;
        font-size: 1.2rem; }
      #details_container .ra-details-scale-prices ul li span, #details_container .ra-udw-scale-prices ul li span {
        display: table-cell; }
        #details_container .ra-details-scale-prices ul li span.left, #details_container .ra-udw-scale-prices ul li span.left {
          padding-right: 1rem;
          border-right: 1px solid #aeaeae; }
        #details_container .ra-details-scale-prices ul li span.right, #details_container .ra-udw-scale-prices ul li span.right {
          padding-left: 1rem;
          text-align: right; }
  #details_container .ra-details-scale-prices.use-modal .headline, #details_container .ra-udw-scale-prices.use-modal .headline {
    display: none; }
  #details_container .ra-details-scale-prices.use-modal ul, #details_container .ra-udw-scale-prices.use-modal ul {
    display: none; }

#details_container #productExtraDesc {
  font-weight: bold; }

#details_container .desktop-ribbons {
  margin-bottom: -1rem; }

#details_container .attribute-ribbons {
  position: relative;
  top: -2rem;
  right: 0;
  height: auto;
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  padding-right: 1rem; }
  #details_container .attribute-ribbons .attribute-ribbon {
    position: relative;
    margin-left: .5rem;
    margin-bottom: .5rem;
    text-align: center;
    height: 4rem;
    width: 4rem; }
    #details_container .attribute-ribbons .attribute-ribbon .ra-icon {
      display: flex;
      font-size: 3rem;
      line-height: 4rem;
      justify-content: center;
      align-items: center; }
    #details_container .attribute-ribbons .attribute-ribbon .ra-icon.made-in {
      font-size: 3.8rem;
      line-height: 2rem;
      margin-bottom: -.5rem; }
    #details_container .attribute-ribbons .attribute-ribbon .ra-icon.made-in-de {
      font-size: 3.8rem;
      line-height: 2rem;
      margin-top: -.3rem; }
    #details_container .attribute-ribbons .attribute-ribbon .flag-icon {
      line-height: 2rem; }
    #details_container .attribute-ribbons .attribute-ribbon.no-border .ra-icon {
      font-size: 4rem; }
    #details_container .attribute-ribbons .attribute-ribbon.custom div.custom-svg {
      display: flex;
      height: 4rem;
      padding: .5rem;
      justify-content: center;
      align-items: center; }
      #details_container .attribute-ribbons .attribute-ribbon.custom div.custom-svg svg {
        margin: auto;
        height: 2.4rem;
        width: auto;
        vertical-align: middle;
        display: block; }
    #details_container .attribute-ribbons .attribute-ribbon.lightgrey {
      color: #e3e3e3; }
    #details_container .attribute-ribbons .attribute-ribbon.border-lightgrey {
      background-color: #FFFFFF;
      border: 1px solid #e3e3e3; }
    #details_container .attribute-ribbons .attribute-ribbon.lightgrey-o {
      color: #f6f6f6; }
    #details_container .attribute-ribbons .attribute-ribbon.border-lightgrey-o {
      background-color: #FFFFFF;
      border: 1px solid #f6f6f6; }
    #details_container .attribute-ribbons .attribute-ribbon.darkgrey {
      color: #575757; }
    #details_container .attribute-ribbons .attribute-ribbon.border-darkgrey {
      background-color: #FFFFFF;
      border: 1px solid #575757; }
    #details_container .attribute-ribbons .attribute-ribbon.darkgrey-o {
      color: #747474; }
    #details_container .attribute-ribbons .attribute-ribbon.border-darkgrey-o {
      background-color: #FFFFFF;
      border: 1px solid #747474; }
    #details_container .attribute-ribbons .attribute-ribbon.border {
      color: #aeaeae; }
    #details_container .attribute-ribbons .attribute-ribbon.border-border {
      background-color: #FFFFFF;
      border: 1px solid #aeaeae; }
    #details_container .attribute-ribbons .attribute-ribbon.confirm {
      color: #308136; }
    #details_container .attribute-ribbons .attribute-ribbon.border-confirm {
      background-color: #FFFFFF;
      border: 1px solid #308136; }
    #details_container .attribute-ribbons .attribute-ribbon.highlight {
      color: #94c947; }
    #details_container .attribute-ribbons .attribute-ribbon.border-highlight {
      background-color: #FFFFFF;
      border: 1px solid #94c947; }
    #details_container .attribute-ribbons .attribute-ribbon.special {
      color: #dc371c; }
    #details_container .attribute-ribbons .attribute-ribbon.border-special {
      background-color: #FFFFFF;
      border: 1px solid #dc371c; }
    #details_container .attribute-ribbons .attribute-ribbon.rating {
      color: #f59100; }
    #details_container .attribute-ribbons .attribute-ribbon.border-rating {
      background-color: #FFFFFF;
      border: 1px solid #f59100; }
  #details_container .attribute-ribbons .ribbon-tooltip {
    position: absolute;
    width: 20rem;
    height: auto;
    z-index: 1000; }
    #details_container .attribute-ribbons .ribbon-tooltip.ribbon-modal-open {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(87, 87, 87, 0.5); }
    #details_container .attribute-ribbons .ribbon-tooltip .ribbon-tooltip-triangle {
      width: 0;
      height: 0;
      border-left: .5rem solid transparent;
      border-right: .5rem solid transparent;
      border-bottom: 0.5rem solid #575757;
      margin-left: 1.5rem;
      display: none;
      z-index: 1000; }
    #details_container .attribute-ribbons .ribbon-tooltip .ribbon-tooltip-inner {
      position: relative;
      width: 20rem;
      height: auto;
      font-size: 1.2rem;
      padding: 1rem;
      background-color: #FFFFFF;
      border: 1px solid #aeaeae;
      color: #575757;
      z-index: 1000;
      transform: translateX(-7.5rem);
      display: none;
      border-radius: .2rem; }
      #details_container .attribute-ribbons .ribbon-tooltip .ribbon-tooltip-inner.mobile-modal {
        position: fixed;
        left: 1rem;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        height: auto;
        font-size: 1.2rem;
        padding: 1rem;
        background-color: #FFFFFF;
        border: 1px solid #aeaeae;
        z-index: 1500;
        display: block;
        border-radius: .2rem;
        width: auto; }
        #details_container .attribute-ribbons .ribbon-tooltip .ribbon-tooltip-inner.mobile-modal .close {
          position: absolute;
          right: .5rem;
          top: .5rem;
          font-size: 2rem;
          vertical-align: text-top;
          line-height: 2rem; }
    #details_container .attribute-ribbons .ribbon-tooltip .ribbon-tooltip-moreinfo {
      margin-top: 1rem; }
  @media (min-width: 768px) {
    #details_container .attribute-ribbons .attribute-ribbon:hover .ribbon-tooltip-inner, #details_container .attribute-ribbons .attribute-ribbon:hover .ribbon-tooltip-triangle, #details_container .attribute-ribbons .ribbon-tooltip:hover .ribbon-tooltip-inner, #details_container .attribute-ribbons .ribbon-tooltip:hover .ribbon-tooltip-triangle {
      transition: all .3s;
      display: block; } }

#details_container .attribute-ribbons.nojs {
  margin-top: 1rem;
  margin-bottom: 1rem; }

#details_container .ra-details-countdown-banner {
  background-image: linear-gradient(to right, #e36d1f, #dc371c);
  color: #FFFFFF;
  position: relative;
  overflow: hidden;
  font-weight: bold;
  padding: 0; }
  #details_container .ra-details-countdown-banner.banner-small {
    min-height: 4rem;
    max-height: 6rem;
    margin: 2rem 0; }
    #details_container .ra-details-countdown-banner.banner-small span.text {
      display: none; }
    #details_container .ra-details-countdown-banner.banner-small span.timer {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding: .5rem 3rem;
      z-index: 3;
      min-height: inherit;
      height: 100%;
      max-height: inherit;
      text-align: center; }
  #details_container .ra-details-countdown-banner.banner-big {
    min-height: 4rem;
    max-height: 6rem;
    margin-bottom: 2rem; }
    #details_container .ra-details-countdown-banner.banner-big span.text {
      margin-left: 4rem;
      font-size: 2.4rem;
      z-index: 2;
      height: 4rem;
      line-height: 4rem;
      letter-spacing: .2rem; }
    #details_container .ra-details-countdown-banner.banner-big span.timer {
      float: right;
      text-align: right;
      padding: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      z-index: 3;
      min-height: inherit;
      max-height: inherit;
      height: 100%; }
    @media (max-width: 991.98px) {
      #details_container .ra-details-countdown-banner.banner-big span.timer {
        float: none;
        padding: .5rem 3rem;
        text-align: center; } }
  #details_container .ra-details-countdown-banner::before {
    content: "";
    position: absolute;
    background-color: #eda271;
    width: 10rem;
    height: 200%;
    top: -100%;
    left: -9rem;
    transform: rotate(25deg);
    z-index: 2; }
  #details_container .ra-details-countdown-banner::after {
    content: "";
    position: absolute;
    background-color: #e98b4c;
    width: 10rem;
    height: 200%;
    top: -100%;
    left: -7rem;
    transform: rotate(25deg);
    z-index: 1; }

#details_container .details-unavailable-article {
  font-weight: bold;
  color: #dc371c;
  margin-bottom: 1rem; }

#details_container .controlGroup.has-error label {
  color: #dc371c; }

#details_container .controlGroup.has-error input {
  border: 1px solid #dc371c; }

#details_container .controlGroup.has-error .error {
  color: #dc371c;
  width: 100%;
  padding: .5rem .5rem .5rem 1rem; }

.ra-details-contact {
  padding: 0 4rem; }
  .ra-details-contact .ra-details-contact-inner {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e3e3e3;
    background-color: #FFFFFF;
    padding: .5rem 1rem;
    border-radius: .5rem;
    margin-top: 2rem;
    box-shadow: 0px 0px 9px 0px #e3e3e3;
    cursor: pointer; }
    .ra-details-contact .ra-details-contact-inner span {
      font-size: 1.6rem;
      font-weight: 700; }
    .ra-details-contact .ra-details-contact-inner .image-container {
      display: flex;
      align-items: center; }
      .ra-details-contact .ra-details-contact-inner .image-container .contact-img {
        border-radius: 50%;
        border: 1px solid #aeaeae;
        overflow: hidden;
        z-index: 5;
        display: inline-block;
        margin: auto .2rem; }
        .ra-details-contact .ra-details-contact-inner .image-container .contact-img img {
          height: 4rem;
          width: 4rem;
          transform: scale(1);
          transition: all .4s linear; }
          .ra-details-contact .ra-details-contact-inner .image-container .contact-img img:hover {
            transform: scale(1.1);
            transition: all .4s linear; }
    .ra-details-contact .ra-details-contact-inner .no-contact-img i {
      font-size: 4rem; }
